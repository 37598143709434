import axios from 'axios';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { multipleAndCheck, multipleOrCheck, navigateToLocatorWithDealPickup, navigateToMenu, ternaryCheck } from '../Utils/WindowUtils';
import _cloneDeep from 'lodash/cloneDeep';
import _isObject from 'lodash/isObject';
import {
  ADD_ONS,
  BREAD,
  CHEESE,
  PASTA,
  PROTEIN,
  RICE,
  SAUCE,
  SEASONINGS,
  VEGETABLE,
  DEFAULT_NUMBER_OF_CHARACTERS,
  PASATA_ID,
  FOUNTAIN_DRINK,
  EXTRAS,
  EGG,
  OMELETE_ID,
  BASE_URL,
  COOKIES,
  DEAL_NON_SD,
  DEAL_SD,
  DEAL_NATIONAL,
  DEAL_SUBWAYMERCH,
  ULTIMATE_BREAKFAST,
  FOUNTAIN_BEVERAGE,
  HOT_BEVERAGE,
  FINDNEWLOCATION,
  CONTINUEORDER,
  SWITCHTOPICKUP,
  KEEPDELIVERY,
  DIGIT_PARTITION_ARRAY,
  DELIVERY,
  APPLY,
  EDIT,
  REMOVE,
  RECENT_ORDER,
  PICKUP,
  ENERGY_K_CAL_ID,
  NON_BONUS,
  BONUS,
} from '../constants/App';
import { commaSeparated, currencyPlacement } from './pageUtils';
import { COOKIE_PACK, FOUNTAIN_DRINK_ID } from '../constants/translations';
import { SWITCH_TO_PICKUP, KEEP_DELIVERY } from '../constants/AnalyticsConstants';
import { CONTINUE_WITHOUT_DEAL, VIEW_PARTICIPATING_LOCATION } from '../constants/ContextConstants';

export const showSpinner = () => {
  const subway_spinner = document.getElementById('subway-spinner');
  if (subway_spinner) {
    document.getElementById('subway-spinner').style.display = 'block';
  }
};
export const hideSpinner = () => {
  const subway_spinner = document.getElementById('subway-spinner');
  if (subway_spinner) {
    document.getElementById('subway-spinner').style.display = 'none';
  }
};

export const showButton = (buttons, ingredient, customizerData) => {
  const mapButtonNames = item => (item.groupName = ingredient);

  const updateButtonName = (index, price) => {
    buttons.buttonNames[index].name += ' ' + '+' + currencyPlacement('$', price.toFixed(2));
    buttons.buttonNames[index].price = price;
  };

  if (buttons?.buttonNames?.length > 0) {
    buttons.buttonNames.map(mapButtonNames);

    if (ingredient === CHEESE) {
      const price = buttons?.extraPrice?.find(item_price => item_price.pricingScheme === 1).price;

      const buttonTextIndex = buttons.buttonNames.findIndex(
        item => item.name === customizerData.extraCheese,
      );

      if (buttonTextIndex > 0) {
        updateButtonName(buttonTextIndex, price);
      }

      return buttons.buttonNames;
    } else if (ingredient === PROTEIN) {
      const deluxeIndex = buttons.buttonNames.findIndex(
        item => item.name === customizerData.deluxe,
      );
      const doubleIndex = buttons.buttonNames.findIndex(
        item =>
          multipleOrCheck(item.name === customizerData.doubleProtien,
            item.name === 'Double Protein',
            item.name === customizerData.double,
            item.name === customizerData.doubleMeat)
      );

      if (deluxeIndex > 0) {
        updateButtonName(deluxeIndex, buttons.buttonNames[deluxeIndex].price);
      }

      if (doubleIndex > 0) {
        updateButtonName(doubleIndex, buttons.buttonNames[doubleIndex].price);
      }

      return buttons.buttonNames;
    } else {
      return buttons.buttonNames;
    }
  }
};

export const getExtraItemsCalories = (currentSelectedItem, regularTxt) => {
  let calories = 0;
  const excludedIngrediants = [BREAD, EGG];
  currentSelectedItem &&
    currentSelectedItem[0].ingredients.forEach(modifier => {
      modifier.options.forEach(ingredient => {
        if (ingredient.selected && !excludedIngrediants.includes(modifier.id)) {
          ingredient.additionalOptions.buttonNames.forEach(button => {
            if (button.selected && button.originalName !== regularTxt) {
              calories = calories + button.calorie;
            }
          });
        }
      });
    });
  return calories
};
export const getIngredientsButton = (
  item,
  option,
  productType,
  customizerData,
  selectedOptions,
  doubleProteinFlag,
  dataPathModified,
) => {
  const { pricingSchemeId, rules } = dataPathModified;
  const isExtrasAvailable = productType.find(itemType => itemType.id === EXTRAS);
  let extrasCheese = false;
  let deluxProtein = false;
  let doubleProtein = false;

  let editBread = false;
  let editToastedBread = false;
  let editGrilledBread = false;
  let editExtraCheese = false;
  let editProteinDouble = false;
  let editProteinDeluxe = false;
  let editMore = false;
  let editLess = false;
  const itemCal = !_isEmpty(option.nutritions)
    ? parseFloat(option.nutritions.find(nutrition => nutrition.id == ENERGY_K_CAL_ID).value)
    : 0;
  const itemCalorie = calorieRoundingRules(itemCal, rules);
  if (selectedOptions && selectedOptions.length > 0) {
    selectedOptions.map(optionsData => {
      if (optionsData.modifierGrpId === BREAD && optionsData.optionId === option.id) {
        if (optionsData.selectedOptionsList && optionsData.selectedOptionsList.length > 0) {
          optionsData.selectedOptionsList.map(list => {
            if (list.optionName === customizerData.toasted && list.inStock) {
              editToastedBread = true;
            }
            if (list.optionName === customizerData.grilled && list.inStock) {
              editGrilledBread = true;
            }
          });
        }
        if (_isEmpty(optionsData.selectedOptionsList) && optionsData.optionId === option.id) {
          editBread = true;
        }
      }
      if (optionsData.modifierGrpId === CHEESE && optionsData.optionId === option.id) {
        editExtraCheese =
          optionsData.selectedOptionsList &&
            optionsData.selectedOptionsList.length > 0 &&
            optionsData.selectedOptionsList[0].optionName === customizerData.extraCheese &&
            optionsData.selectedOptionsList[0].inStock
            ? true
            : false;
      }
      if (
        item === PROTEIN &&
        (optionsData.optionName === customizerData.double ||
          optionsData.optionName === customizerData.doubleMeat ||
          optionsData.optionName === customizerData.doubleProtien)
      ) {
        editProteinDouble = true;
      }
      if (item === PROTEIN && optionsData.optionName === customizerData.deluxe) {
        editProteinDeluxe = true;
      }
      if (
        optionsData.optionName.includes(customizerData.more) &&
        optionsData.optionId === option.id
      ) {
        editMore = true;
      }
      if (
        optionsData.optionName.includes(customizerData.less) &&
        optionsData.optionId === option.id
      ) {
        editLess = true;
      }
    });
  }

  if (!!isExtrasAvailable) {
    extrasCheese = productType
      .find(group => group.id === EXTRAS)
      .options.find(
        optionsData =>
          _get(optionsData.translations, ['0', 'displayName'], '') === customizerData.extraCheese,
      );

    deluxProtein = productType
      .find(group => group.id === EXTRAS)
      .options.find(
        optionsData =>
          _get(optionsData.translations, ['0', 'displayName'], '') === customizerData.deluxe,
      );

    doubleProtein = productType
      .find(group => group.id === EXTRAS)
      .options.find(
        optionsData =>
          _get(optionsData.translations, ['0', 'displayName'], '') === customizerData.double ||
          _get(optionsData.translations, ['0', 'displayName'], '') ===
          customizerData.doubleProtien ||
          _get(optionsData.translations, ['0', 'displayName'], '') === customizerData.doubleMeat,
      );
  }

  if (item === BREAD) {
    const buttonData = [];
    if (option.breadOptions.length > 0) {
      const defaultToastedSubOption = option.breadOptions.some(subOption => {
        if (
          !_isEmpty(subOption.translations) &&
          subOption.translations[0].displayName == customizerData.toasted
        ) {
          return subOption.isDefault && subOption.inStock;
        }
      });
      const defaultGrilledSubOption = option.breadOptions.some(subOption => {
        if (
          !_isEmpty(subOption.translations) &&
          subOption.translations[0].displayName == customizerData.grilled
        ) {
          return subOption.isDefault && subOption.inStock;
        }
      });
      const isChangable = option.breadOptions.some(
        subOption => subOption.isDefaultChangeable || !subOption.isDefaultChangeable,
      );
      const isStock = option.breadOptions.every(subOption => subOption.inStock === true);
      const selectBread = () => {
        if (editBread) {
          return true;
        } else {
          return editToastedBread || editGrilledBread
            ? false
            : !defaultToastedSubOption && !defaultGrilledSubOption;
        }
      };
      if (isChangable) {
        buttonData.push({
          id: customizerData.regular,
          name: customizerData.regular,
          originalName: customizerData.regular,
          selected: selectBread(),
          isDefault: defaultToastedSubOption || defaultGrilledSubOption ? false : true,
          inStock: true,
          addData: false,
          groupName: item,
          calorie: itemCalorie,
        });
      }
      option.breadOptions.map(subOption => {
        if (subOption.isDefaultChangeable || !subOption.isDefaultChangeable) {
          let selectedBtn = (subOption.isDefault && !editBread) || false;
          let isToasted = false;
          let isGrilled = false;
          if (editToastedBread && editGrilledBread) {
            selectedBtn = false;
          } else {
            if (
              editToastedBread &&
              subOption.translations[0].displayName == customizerData.toasted
            ) {
              selectedBtn = true;
              isToasted = true;
            }
            if (
              editGrilledBread &&
              subOption.translations[0].displayName == customizerData.grilled
            ) {
              selectedBtn = true;
              isToasted = false;
              isGrilled = true;
            }
          }
          buttonData.push({
            id: subOption.id,
            name: subOption.translations[0].displayName,
            originalName: subOption.translations[0].displayName,
            isDefault: subOption.isDefault && subOption.inStock,
            isDefaultChangeable: subOption.isDefaultChangeable,
            inStock: subOption.inStock,
            groupName: item,
            selected:
              !editToastedBread && !editGrilledBread
                ? subOption.inStock && selectedBtn
                : selectedButton(
                  subOption.inStock,
                  selectedBtn,
                  isToasted,
                  isGrilled,
                  subOption.translations[0].displayName,
                  customizerData,
                ),
            addData: false,
            calorie: itemCalorie,
          });
        }
      });
      if (option.breadOptions.length > 1) {
        buttonData.push({
          id: customizerData.toastedAndGrilled,
          name: customizerData.toastedAndGrilled,
          originalName: customizerData.toastedAndGrilled,
          selected: editToastedBread && editGrilledBread,
          isDefault: defaultToastedSubOption && defaultGrilledSubOption ? true : false,
          inStock: isStock ? true : false,
          addData: false,
          groupName: item,
          calorie: itemCalorie,
        });
      }
      return {
        buttonNames: buttonData,
      };
    } else {
      return { buttonNames: [] };
    }
  }

  if (item === CHEESE) {
    if (!!extrasCheese && option.more > 0) {
      return {
        buttonNames: [
          {
            name: customizerData.regular,
            originalName: customizerData.regular,
            selected: editExtraCheese ? false : true,
            isDefault: true,
            inStock: true,
            groupName: item,
            calorie: itemCalorie,
          },
          {
            name: extrasCheese.translations[0].displayName,
            originalName: extrasCheese.translations[0].displayName,
            selected: editExtraCheese ? true : false,
            id: extrasCheese.id,
            inStock: extrasCheese.inStock,
            groupName: item,
            calorie: itemCalorie * option.more - itemCalorie,
          },
        ],
        extraPrice: extrasCheese.prices,
      };
    } else {
      return { buttonNames: [] };
    }
  }

  if (item === PROTEIN && option.id != customizerData.sideOfBaconId) {
    if (!!deluxProtein && !!doubleProtein) {
      const proteinVal = doubleProtein.translations[0].displayName;
      return {
        buttonNames: [
          {
            name: customizerData.regular,
            originalName: customizerData.regular,
            selected: (editProteinDouble && doubleProteinFlag) || editProteinDeluxe ? false : true,
            isDefault: true,
            inStock: true,
            isDoubleProtein: false,
            groupName: item,
            calorie: itemCalorie,
          },
          {
            name: deluxProtein.translations[0].displayName,
            originalName: deluxProtein.translations[0].displayName,
            selected: editProteinDeluxe ? true : false,
            id: deluxProtein.id,
            inStock: deluxProtein.inStock,
            price: getPrice(deluxProtein.prices, pricingSchemeId),
            isDoubleProtein: false,
            isDefault: false,
            groupName: item,
            calorie: itemCalorie * 1.5 - itemCalorie,
          },
          {
            name: proteinVal,
            originalName: proteinVal,
            selected: editProteinDouble ? true : false,
            id: doubleProtein.id,
            inStock: doubleProtein.inStock,
            price: getPrice(doubleProtein.prices, pricingSchemeId),
            isDoubleProtein: true,
            isDefault: false,
            groupName: item,
            calorie: itemCalorie,
          },
        ],
        extraPrice: doubleProtein.prices,
      };
    } else if (!!deluxProtein) {
      return {
        buttonNames: [
          {
            name: customizerData.regular,
            originalName: customizerData.regular,
            selected: editProteinDeluxe ? false : true,
            isDefault: !deluxProtein.isDefault,
            inStock: true,
            groupName: item,
            calorie: itemCalorie,
          },
          {
            name: deluxProtein.translations[0].displayName,
            originalName: deluxProtein.translations[0].displayName,
            selected: editProteinDeluxe ? true : false,
            id: deluxProtein.id,
            inStock: deluxProtein.inStock,
            isDefault: deluxProtein.isDefault,
            price: getPrice(deluxProtein.prices, pricingSchemeId),
            groupName: item,
            calorie: itemCalorie * 1.5 - itemCalorie,
          },
        ],
        extraPrice: deluxProtein.prices,
        disclaimer: false,
      };
    } else if (!!doubleProtein) {
      const proteinVal = doubleProtein.translations[0].displayName;
      return {
        buttonNames: [
          {
            name: customizerData.regular,
            originalName: customizerData.regular,
            selected: editProteinDouble && doubleProteinFlag ? false : true,
            isDefault: !doubleProtein.isDefault,
            inStock: true,
            isDoubleProtein: false,
            groupName: item,
            calorie: itemCalorie,
          },
          {
            name: proteinVal,
            originalName: proteinVal,
            selected: editProteinDouble ? true : false,
            id: doubleProtein.id,
            isDefault: doubleProtein.isDefault,
            inStock: doubleProtein.inStock,
            price: getPrice(doubleProtein.prices, pricingSchemeId),
            isDoubleProtein: true,
            groupName: item,
            calorie: itemCalorie,
          },
        ],
        extraPrice: doubleProtein.prices,
      };
    } else {
      return { buttonNames: [] };
    }
  }

  if (
    (option.isDefault && !option.isDefaultChangeable) ||
    (option.less === 0 && option.more === 0)
  ) {
    return { buttonNames: [] };
  }

  return {
    buttonNames: [
      {
        name: customizerData.less,
        originalName: customizerData.less,
        selected: editLess ? true : false,
        inStock: true,
        groupName: item,
        calorie: itemCalorie * option.less - itemCalorie,
      },
      {
        name: customizerData.regular,
        originalName: customizerData.regular,
        selected: editLess || editMore ? false : true,
        isDefault: true,
        inStock: true,
        groupName: item,
        calorie: itemCalorie,
      },
      {
        name: customizerData.more,
        originalName: customizerData.more,
        selected: editMore ? true : false,
        inStock: true,
        groupName: item,
        calorie: itemCalorie * option.more - itemCalorie,
      },
    ],
  };
};

export const getSelectedIngredient = (item, index) => {
  let result = false;
  if (item.min == item.max) {
    if (index === 0) {
      result = true;
    }
  }
  return result;
};

export const getDescription = (item, customizerData) => {
  const extraList = ['Extra Cheese', 'Extra cheese', 'DoubleMeat', 'Deluxe', 'Double'];
  const extraItemLength =
    item.id === EXTRAS && item.options.filter(data => !extraList.includes(data.name)).length;
  if (item.min === 1 && item.max === 1 && item.options.length > 1) {
    return customizerData.selectOne;
  }
  if (item.max > item.options.length && item.id != EXTRAS) {
    return customizerData.selectUpTo + ' ' + item.options.length;
  }
  if (item.min < item.max && item.options.length >= 1 && item.id != EXTRAS) {
    return customizerData.selectUpTo + ' ' + item.max;
  }
  if (extraItemLength) {
    return item.max > extraItemLength
      ? customizerData.selectUpTo + ' ' + extraItemLength
      : customizerData.selectUpTo + ' ' + item.max;
  }
  return '';
};

export const getItemName = (name, isAsterisk, categoryID, sideofToast, bread) => {
  if ((PASATA_ID === categoryID || OMELETE_ID === categoryID) && name === bread) {
    name = sideofToast;
  }
  return isAsterisk ? `${name}*` : name;
};

export const getCalories = (rules, calValue, selectedOptions, option, customizerData, itemID) => {
  if (!_isEmpty(selectedOptions)) {
    const selectedItem = selectedOptions.filter(item => item.optionId === option.id);
    const isExtra = selectedOptions.filter(item => item.modifierGrpId === EXTRAS);
    const isProteinDouble = isExtra.some(
      item =>
        item.optionName === customizerData.double || item.optionName === customizerData.doubleMeat || item.optionName === customizerData.doubleProtien,
    );
    const isProteinDeluxe = isExtra.some(item => item.optionName === customizerData.deluxe);
    if (!_isEmpty(selectedItem)) {
      let cal = calorieRoundingRules(calValue, rules);
      const optionList = _get(selectedItem, ['0', 'selectedOptionsList'], null);
      if (
        optionList &&
        selectedItem[0].inStock &&
        optionList[0].inStock &&
        optionList[0].optionName === customizerData.extraCheese
      ) {
        return cal * option.more;
      }
      if (selectedItem[0].inStock && selectedItem[0].optionName.includes(customizerData.more)) {
        return cal * option.more;
      }
      if (selectedItem[0].inStock && selectedItem[0].optionName.includes(customizerData.less)) {
        return cal * option.less;
      }
      return cal;
    }
    if (_isEmpty(selectedItem) && itemID === PROTEIN) {
      if (isProteinDouble) {
        return calorieRoundingRules(calValue, rules) * 2;
      }
      if (isProteinDeluxe) {
        return calorieRoundingRules(calValue, rules) * 1.5;
      }
      return calorieRoundingRules(calValue, rules);
    }
    if (_isEmpty(selectedItem)) {
      return calorieRoundingRules(calValue, rules);
    }
  } else {
    calValue = calorieRoundingRules(calValue, rules);
  }
  return calValue;
};

export const getDisplayName = (
  option,
  name,
  selectedOptions,
  customizerData,
  itemID,
  doubleProteinFlag,
) => {
  if (!_isEmpty(selectedOptions)) {
    const selectedItem = selectedOptions.filter(item => item.optionId === option.id);
    const isExtra = selectedOptions.filter(item => item.modifierGrpId === EXTRAS);
    const isProtein = isExtra.filter(
      item =>
        item.optionName === customizerData.double ||
        item.optionName === customizerData.doubleMeat ||
        item.optionName === customizerData.doubleProtien ||
        item.optionName === customizerData.deluxe,
    );
    if (
      !_isEmpty(selectedItem) &&
      (selectedItem[0].optionName != customizerData.double ||
        selectedItem[0].optionName != customizerData.doubleProtien ||
        selectedItem[0].optionName != customizerData.doubleMeat ||
        selectedItem[0].optionName != customizerData.deluxe)
    ) {
      const optionList =
        selectedItem[0].inStock && _get(selectedItem, ['0', 'selectedOptionsList'], null);
      const portionName =
        _get(selectedItem, ['0', 'portion'], 1) > 1 &&
          (_get(selectedItem, ['0', 'modifierGrpId'], '') == 'Cheese' ||
            _get(selectedItem, ['0', 'modifierGrpId'], '') == 'Extra')
          ? `${selectedItem[0].portion}X ${selectedItem[0].optionName}`
          : selectedItem[0].optionName;
      if (optionList && optionList.length === 1 && optionList[0].inStock) {
        return optionList[0].optionName === customizerData.extraCheese
          ? optionList[0].optionName + ' - ' + portionName
          : optionList[0].optionName + ' ' + portionName;
      }
      if (optionList && optionList.length > 1) {
        return customizerData.toastedAndGrilled + ' ' + portionName;
      }
      return selectedItem[0].inStock ? portionName : name;
    }
    if (_isEmpty(selectedItem) && itemID === BREAD) {
      if (option.breadOptions && option.breadOptions.length > 0) {
        option.breadOptions.map(list => {
          if (list.inStock && list.isDefault) {
            name = list.translations[0].displayName + ' ' + name;
          }
        });
      }
    }
    if (
      _isEmpty(selectedItem) &&
      !_isEmpty(isExtra) &&
      itemID === PROTEIN &&
      option.id != customizerData.sideOfBaconId
    ) {
      if (isProtein.length > 0 && doubleProteinFlag) {
        return isProtein[0].optionName + ' ' + name;
      }
      return name;
    }
  }
  if (_isEmpty(selectedOptions)) {
    if (option.breadOptions.length > 0) {
      option.breadOptions.map(button => {
        if (button.isDefault && button.inStock) {
          name = button.translations[0].displayName + ' ' + name;
        }
      });
    }
  }

  return name;
};

export const getProductType = productData => {
  const types = [];
  Object.values(productData).forEach(item => {
    types.push({
      id: item.id,
      name: item.name,
      calories: item.calories,
      isSelected: item.defaultSelected,
    });
  });
  return types;
};
export const ingredientTypeSelection = (
  list,
  option,
  item,
  ingredientName,
  customizerData,
  calorie,
  prevselectedType,
  optionId,
  ingredientNameDisplay,
  roundingRules,
) => {
  let selectedPrice;
  let unSelectedPrice;
  list.map(ingrdientData => {
    if (ingrdientData.id === item) {
      if (ingrdientData.id === item) {
        ingrdientData.getPrice = option.price;
      }
      ingrdientData.options.map(optionList => {
        optionList.additionalOptions &&
          optionList.additionalOptions.buttonNames.length > 0 &&
          optionList.additionalOptions.buttonNames.map(button => {
            optionList.orginalNmae = option.name;
            if (ingrdientData.id === PROTEIN) {
              if (button.originalName == option.originalName) {
                if (!button.selected) {
                  button.selected = true;
                  if (option.name.includes(customizerData.deluxe)) {
                    selectedPrice = option.price;
                    ingrdientData.getPrice = option.price;
                    if (
                      optionList.name.includes('Double Protein') ||
                      optionList.name.includes(customizerData.double) ||
                      optionList.name.includes(customizerData.doubleMeat) ||
                      optionList.name.includes(customizerData.doubleProtien)
                    ) {
                      let calorievals = optionList.calories - (optionList.calories * 1.5) / 2;
                      calorie = calorie - calorievals;
                      optionList.calories = (optionList.calories * 1.5) / 2;
                    } else {
                      optionList.calories = optionList.calories * 1.5;
                      let calorieValue = optionList.calories - optionList.calories / 1.5;
                      calorie = calorie + calorieValue;
                    }
                    const portionSizeName =
                      optionList.portionSize > 1 ? `${optionList.portionSize}X ` : '';
                    optionList.name =
                      portionSizeName +
                      option.name.split('+').shift().trim() +
                      ' ' +
                      optionList.name
                        .replace('Double Protein', '')
                        .replace(customizerData.double, '')
                        .replace(customizerData.doubleProtien, '')
                        .replace(customizerData.doubleMeat, '')
                        .replace(`${optionList.portionSize}X`, '');
                  } else if (
                    option.name.includes('Double Protein') ||
                    option.name.includes(customizerData.double) ||
                    option.name.includes(customizerData.doubleMeat) ||
                    option.name.includes(customizerData.doubleProtien)
                  ) {
                    selectedPrice = option.price;
                    ingrdientData.getPrice = option.price;
                    if (
                      !optionList.name.includes(customizerData.deluxe) &&
                      (!optionList.name.includes(customizerData.double) ||
                        !optionList.name.includes(customizerData.doubleProtien) ||
                        !optionList.name.includes(customizerData.doubleMeat))
                    ) {
                      optionList.calories = optionList.calories * 2;
                      let calorieValue = optionList.calories - optionList.calories / 2;
                      calorie = calorie + calorieValue;
                    } else {
                      const calorievals = (optionList.calories / 1.5) * 2 - optionList.calories;
                      calorie = calorie + calorievals;
                      optionList.calories = (optionList.calories * 2) / 1.5;
                    }
                    const portionSizeName =
                      optionList.portionSize > 1 ? `${optionList.portionSize}X ` : '';
                    optionList.name =
                      portionSizeName +
                      option.name.split('+').shift().trim() +
                      ' ' +
                      optionList.name
                        .replace(customizerData.deluxe, '')
                        .replace(`${optionList.portionSize}X`, '');
                  } else {
                    if (optionList.name.includes(customizerData.double) || optionList.name.includes(customizerData.doubleProtien) || optionList.name.includes(customizerData.doubleMeat)) {
                      const val = optionList.calories - optionList.calories / 2;
                      calorie = calorie - val;
                      optionList.calories = optionList.calories / 2;
                    } else {
                      if (optionList.name.includes(customizerData.deluxe)) {
                        let calVal = optionList.calories - optionList.calories / 1.5;
                        calorie = calorie - calVal;
                        optionList.calories = optionList.calories / 1.5;
                      } else {
                        optionList.calories = optionList.calories / 1.5;
                      }
                    }

                    optionList.name = optionList.name
                      .replace(customizerData.deluxe, '')
                      .replace(customizerData.doubleProtien, '')
                      .replace(customizerData.doubleMeat, '')
                      .replace(customizerData.double, '')
                      .replace('Double Protein', '');
                  }
                }
              } else {
                if (
                  (button.name.includes(customizerData.double) && button.selected) ||
                  (button.name.includes(customizerData.doubleProtien) && button.selected) ||
                  (button.name.includes(customizerData.doubleMeat) && button.selected) ||
                  (button.name.includes(customizerData.deluxe) && button.selected)
                ) {
                  unSelectedPrice = button.price;
                  ingrdientData.getPrice = -option.price;
                }
                button.selected = false;
              }
            } else {
              if (optionList.name == ingredientName) {
                if (button.originalName == option.originalName) {
                  if (!button.selected) {
                    button.selected = true;
                    if (option.name === customizerData.toasted) {
                      optionList.name =
                        option.name +
                        ' ' +
                        optionList.name
                          .replace(customizerData.toastedAndGrilled, '')
                          .replace(customizerData.grilled, '');
                      ingredientName =
                        option.name +
                        ' ' +
                        ingredientName
                          .replace(customizerData.toastedAndGrilled, '')
                          .replace(customizerData.grilled, '');
                    } else if (option.name === customizerData.grilled) {
                      optionList.name =
                        option.name +
                        ' ' +
                        optionList.name
                          .replace(customizerData.toastedAndGrilled, '')
                          .replace(customizerData.toasted, '');
                      ingredientName =
                        option.name +
                        ' ' +
                        ingredientName
                          .replace(customizerData.toastedAndGrilled, '')
                          .replace(customizerData.toasted, '');
                    } else if (option.name === customizerData.toastedAndGrilled) {
                      optionList.name =
                        option.name +
                        ' ' +
                        optionList.name
                          .replace(customizerData.toasted, '')
                          .replace(customizerData.grilled, '');
                      ingredientName =
                        option.name +
                        ' ' +
                        ingredientName
                          .replace(customizerData.toasted, '')
                          .replace(customizerData.grilled, '');
                    } else if (option.name.includes(customizerData.extraCheese)) {
                      optionList.name =
                        option.name.split('+').shift().trim() + ' - ' + optionList.name;
                      ingredientName =
                        option.name.split('+').shift().trim() + ' - ' + ingredientName;
                      selectedPrice = button.price;
                      ingrdientData.getPrice = option.price;
                      calorie = optionList.calories + calorie;
                      optionList.calories = optionList.calories * optionList.more;
                    } else if (option.name === customizerData.more) {
                      if (optionList.name.includes(customizerData.less)) {
                        calorie = calorie + (optionList.calories + optionList.initialCalorie);
                        optionList.calories = calorieRoundingRules(
                          optionList.initialCalorie * optionList.more,
                          roundingRules,
                        );
                      } else {
                        calorie = calorie + optionList.calories;
                        optionList.calories = calorieRoundingRules(
                          parseFloat(optionList.calories) * optionList.more,
                          roundingRules,
                        );
                      }
                      optionList.name = option.name.concat(
                        ' ',
                        optionList.name.replace(customizerData.less, ''),
                      );
                      ingredientName = option.name.concat(
                        ' ',
                        ingredientName.replace(customizerData.less, ''),
                      );
                    } else if (option.name === customizerData.less) {
                      if (optionList.name.includes(customizerData.more)) {
                        optionList.calories = calorieRoundingRules(
                          optionList.initialCalorie * optionList.less,
                          roundingRules,
                        );
                        calorie = calorie - (optionList.calories + optionList.initialCalorie);
                      } else {
                        optionList.calories = calorieRoundingRules(
                          parseFloat(optionList.calories) * optionList.less,
                          roundingRules,
                        );
                        calorie = calorie - optionList.calories;
                      }
                      optionList.name = option.name.concat(
                        ' ',
                        optionList.name.replace(customizerData.more, ''),
                      );
                      ingredientName = option.name.concat(
                        ' ',
                        ingredientName.replace(customizerData.more, ''),
                      );
                    } else {
                      if (optionList.name.includes(customizerData.more)) {
                        optionList.calories = parseFloat(optionList.calories) / optionList.more;
                        calorie = calorie - optionList.calories;
                        optionList.name = optionList.name
                          .replace(customizerData.deluxe, '')
                          .replace(customizerData.toasted, '')
                          .replace(customizerData.more, '')
                          .replace(customizerData.less, '')
                          .replace(customizerData.double, '')
                          .replace(customizerData.doubleMeat, '');
                        ingredientName = ingredientName
                          .replace(customizerData.deluxe, '')
                          .replace(customizerData.toasted, '')
                          .replace(customizerData.more, '')
                          .replace(customizerData.less, '')
                          .replace(customizerData.double, '')
                          .replace(customizerData.doubleMeat, '');
                      } else if (optionList.name.includes(customizerData.less)) {
                        calorie = calorie + optionList.calories;
                        optionList.calories = calorieRoundingRules(
                          optionList.initialCalorie,
                          roundingRules,
                        );
                        optionList.name = optionList.name
                          .replace(customizerData.deluxe, '')
                          .replace(customizerData.toasted, '')
                          .replace(customizerData.more, '')
                          .replace(customizerData.less, '')
                          .replace(customizerData.doubleMeat, '');
                        ingredientName = ingredientName
                          .replace(customizerData.deluxe, '')
                          .replace(customizerData.toasted, '')
                          .replace(customizerData.more, '')
                          .replace(customizerData.less, '')
                          .replace(customizerData.doubleMeat, '');
                      } else if (optionList.name.includes(customizerData.extraCheese)) {
                        optionList.additionalOptions.buttonNames.map(name => {
                          if (isEqual(optionList.name, ingredientName)) {
                            unSelectedPrice = name.price;
                            ingrdientData.getPrice = -name.price;
                          }
                        });
                        const value = optionList.calories / optionList.more;
                        optionList.calories = value;
                        calorie = calorie - optionList.calories;
                        optionList.name = optionList.name.replace(
                          `${customizerData.extraCheese} -`,
                          '',
                        );
                        ingredientName = ingredientName.replace(
                          `${customizerData.extraCheese} -`,
                          '',
                        );
                      } else {
                        optionList.name = optionList.name
                          .replace(customizerData.deluxe, '')
                          .replace(customizerData.toastedAndGrilled, '')
                          .replace(customizerData.toasted, '')
                          .replace(customizerData.grilled, '')
                          .replace(customizerData.doubleProtien, '')
                          .replace(customizerData.more, '')
                          .replace(customizerData.less, '')
                          .replace(customizerData.doubleMeat, '')
                          .replace(customizerData.double, '')
                        ingredientName = ingredientName
                          .replace(customizerData.deluxe, '')
                          .replace(customizerData.toastedAndGrilled, '')
                          .replace(customizerData.toasted, '')
                          .replace(customizerData.grilled, '')
                          .replace(customizerData.doubleProtien, '')
                          .replace(customizerData.more, '')
                          .replace(customizerData.less, '')
                          .replace(customizerData.doubleMeat, '')
                          .replace(customizerData.double, '')
                      }
                    }
                  }
                } else {
                  button.selected = false;
                }
              } else {
                if (optionList.name.includes(customizerData.extraCheese)) {
                  optionList.name = optionList.orginalName;
                  optionList.calories = optionList.initialCalorie;
                  calorie = calorie - optionList.calories;
                }
              }
            }
          });
      });
    }
  });
  if (selectedPrice && unSelectedPrice) {
    selectedPrice = selectedPrice - unSelectedPrice;
    unSelectedPrice = selectedPrice;
  }
  return { list, selectedPrice: selectedPrice, unSelectedPrice: unSelectedPrice, calorie: calorie };
};

export const MidpointRounding = {
  //
  // Summary:
  //     When a number is halfway between two others, it is rounded toward the nearest
  //     even number.
  ToEven: 0,
  //
  // Summary:
  //     When a number is halfway between two others, it is rounded toward the nearest
  //     number that is away from zero.
  AwayFromZero: 1,
};

export const calorieRoundingRules = (valueToRound, calorieRoundings = []) => {
  let roundedValue = 0;
  calorieRoundings &&
    calorieRoundings.find(rule => {
      if (
        (valueToRound > rule.lowerLimit ||
          (rule.lowerLimitInclusive && valueToRound == rule.lowerLimit)) &&
        (valueToRound < rule.upperLimit ||
          (rule.upperLimitInclusive && valueToRound == rule.upperLimit))
      ) {
        if (rule.roundToValue == 0) {
          roundedValue = rule.roundToInt;
          return roundedValue;
        }
        let roundingMode = rule.midpointRoundingAwayFromZero
          ? MidpointRounding.AwayFromZero
          : MidpointRounding.ToEven;
        roundedValue = rule.roundToInt
          ? Math.round(valueToRound / rule.roundToInt, roundingMode) * rule.roundToInt
          : Math.round(valueToRound / rule.roundToValue, roundingMode) * rule.roundToValue;
        return roundedValue;
      }
    });
  return roundedValue;
};

export const getNutritionCalcInfo = (ingredients, selectedType) => {
  let result;
  if (ingredients.length > 0) {
    ingredients.map(ingredientsList => {
      ingredientsList.options.map(option => {
        if (option.isDefault && option.selected) {
          result = selectedType[0].nutrition;
        }
      });
    });
  }
  return result;
};
const getBundleItems = (dataArray) => {
  const bundleItems = [];

  dataArray.forEach(item => {
    const mainItems = { ...item };
    let comboItems = null;
    if (mainItems.combo) {
      comboItems = mainItems.combo.ComboItems;
      delete mainItems.combo;
    }

    bundleItems.push(mainItems);
    if (comboItems) {
      comboItems = comboItems.map((val) => {
        val["quantity"] = 1;
        return val;
      })
      bundleItems.push(...comboItems);
    }
  });

  return bundleItems;
};
export const getCartItemsPayload = (data, customizerData, siteName, isKidsMeal, isComboLiteCategory, bundleData, cartItemID, fromPdp, selectedComboTracker,cartQuantity) => {
  return {
    CartId: orderViewModel.currentOrder.cartId,
    SuppressedProductId: selectedComboTracker?.suppressedProductId,
    SuppressedProductName: selectedComboTracker?.suppressedProductName,
    LocationId: customizerData.restaurantId,
    StoreId: customizerData.restaurantId,
    curbside: {
      curbsideInstructions: '',
    },
    dineIn: false,
    fulfillmentType: orderViewModel.orderType,
    isKidsMeal: isKidsMeal,
    IsReactResponse: true,
    fromPdp: fromPdp,
    items: isComboLiteCategory ? {
      RemoveCartItemIds: cartItemID && [cartItemID],
    } : {
      AddCartItems: data,
    },
    bundles: isComboLiteCategory ? {
      AddBundleItems: [{
        bundleId: _get(bundleData, ['bundleId'], ''),
        bundleName: _get(bundleData, ['bundleName'], ''),
        itemType: "BUNDLE",
        bundleMasterPromotionId: _get(bundleData, ['bundleMasterPromotionId'], ''),
        quantity: getUrlFlagId('referer') ? cartQuantity : 1,
        BundleItems: getBundleItems(data)
      }],
    } : null,
    orderInstructions: '',
    pricingScheme: '1',
    sitename: siteName,
    Delivery:
      orderViewModel.orderType == 'delivery'
        ? {
          DeliveryAddress: {
            StreetAddressLine1: orderViewModel.DeliveryAddress.StreetAddressLine1,
            StreetAddressLine2: orderViewModel.addressData.StreetAddressLine2,
            City: orderViewModel.DeliveryAddress.City,
            State: orderViewModel.DeliveryAddress.State,
            Zip: orderViewModel.DeliveryAddress.Zip,
            Country: orderViewModel.DeliveryAddress.Country,
          },
          QuoteId: orderViewModel.DeliveryAddress.QuoteId,
        }
        : null,
  };
};

export const updateCartItemsPayload = (data, customizerData, siteName, curbsideInstructions, isKidsMeal, isComboLiteCategory, bundleData, selectedComboTracker) => {
  return {
    CartId: orderViewModel.currentOrder.cartId,
    SuppressedProductId: selectedComboTracker?.suppressedProductId,
    SuppressedProductName: selectedComboTracker?.suppressedProductName,
    LocationId: customizerData.restaurantId,
    StoreId: customizerData.restaurantId,
    curbside: {
      curbsideInstructions: curbsideInstructions,
    },
    Delivery:
      orderViewModel.orderType == 'delivery'
        ? {
          DeliveryAddress: {
            StreetAddressLine1: orderViewModel.DeliveryAddress.StreetAddressLine1,
            StreetAddressLine2: orderViewModel.addressData.StreetAddressLine2,
            City: orderViewModel.DeliveryAddress.City,
            State: orderViewModel.DeliveryAddress.State,
            Zip: orderViewModel.DeliveryAddress.Zip,
            Country: orderViewModel.DeliveryAddress.Country,
          },
          QuoteId: orderViewModel.DeliveryAddress.QuoteId,
        }
        : null,
    dineIn: false,
    fulfillmentType: orderViewModel.orderType,
    isKidsMeal: isKidsMeal,
    IsReactResponse: true,
    items: isComboLiteCategory ? null : {
      UpdateCartItems: data,
    },
    bundles: isComboLiteCategory ? {
      UpdateBundleCartItems: [{
        bundleId: _get(bundleData, ['bundleId'], ''),
        bundleName: _get(bundleData, ['bundleName'], ''),
        itemType: _get(bundleData, ['itemType'], ''),
        bundleMasterPromotionId: _get(bundleData, ['bundleMasterPromotionId'], ''),
        CartItemId: ternaryCheck(!_isEmpty(_get(bundleData, ['bundleItemId'], '')),
          _get(bundleData, ['bundleItemId'], ''),
          _get(bundleData, ['id'], '')),
        isBundleItem: _get(bundleData, ['isBundleItem'], ''),
        quantity: _get(bundleData, ['bundleQuantity'], ''),
        BundleItems: getBundleItems(data)
      }],
    } : null,
    orderInstructions: '',
    pricingScheme: '1',
    quoteId: null,
    sitename: siteName,
  };
};

/**
 * price converter
 * @param {*} price
 * @param {*} quantity
 */
export function priceConverter(price, quantity = 1) {
  return parseFloat(price * quantity).toFixed(2);
}
/**
 * Check is it local host
 */
export function isLocal() {
  return window.location.origin === 'http://localhost:4000';
}
/**
 * Set BAU elements
 */
export function hideBAUElements(item) {
  if (item === 'header') {
    const stickyHeader = document.querySelector('.sticky-header');
    const defaultHeaderComp = document.querySelector('.defaultHeader__comp');
    const headerSticky = document.querySelector('.header-sticky');
    headerSticky && headerSticky.classList.add('d-none');
    stickyHeader && stickyHeader.classList.add('d-none');
    defaultHeaderComp && defaultHeaderComp?.classList.add('d-none');
  }
  if (item === 'footer') {
    document.querySelector('.component_footer')?.classList.add('d-none');
  }
}
/**
 * Remove BAU elements
 */
export function showBAUElements(item) {
  if (item === 'header') {
    const stickyHeader = document.querySelector('.sticky-header');
    const defaultHeaderComp = document.querySelector('.defaultHeader__comp');
    const headerSticky = document.querySelector('.header-sticky');
    headerSticky && headerSticky.classList.remove('d-none');
    stickyHeader && stickyHeader.classList.remove('d-none');
    defaultHeaderComp && defaultHeaderComp.classList.remove('d-none');
  }
  if (item === 'footer') {
    document.querySelector('.component_footer')?.classList.remove('d-none');
  }
}

/**
 * Price + Calories converter
 * eg. $9.90 • +940 Cals
 * @param {*} price value
 * @param {*} calories value
 * @param {*} calsText translated text for Cals
 */
export function priceCaloriesConverter(price, calories, calsText, isCombo, upToText, masterPromotionId='') {
  calories = Math.floor(parseInt(calories));
  const upTo = isCombo ? upToText ?? '' : '';
  return price
    ? `${ternaryCheck(masterPromotionId == "1", '+', '')}${currencyPlacement('$', price.toFixed(2))} • ${upTo} ${calories} ${calsText}`
    : `${upTo} ${calories} ${calsText}`;
}

/**
 * filter by removable ingredient items
 * @param {*} ingredient ingredient name
 */
export function isRemovableItems(ingredient) {
  return (
    ingredient !== BREAD && ingredient !== PROTEIN && ingredient !== EGG && ingredient !== PASTA
  );
}
/*
category id list:-
870:- fountain drinks
873:- (breakfast sides )
866:- salads (sides)
867:- chips (sides)
869:- bottled drinks
868:- galletas ( cookies )
*/
export function categoryCheckItem(categoryId) {
  return [869, 873, 866, 867, 868, 870, 871, 21, 22, 795, 796, 9, 786, 788, 10, 847, 886].includes(
    categoryId,
  );
}

/*
Set Cookie
*/

export const setCookie = (name, value, days) => {
  var d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = name + '=' + value + '; ' + expires + '; path=/';
};

/*
Get Cookie
*/

export const getCookie = cname => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (const element of ca) {
    let c = element;
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

/*
translation for upselmodal
*/

export const addToBagName = (addToCart, updatedName, customizerData) => {
  if (addToCart && !updatedName) {
    return customizerData.addToBag;
  } else if (updatedName) {
    return customizerData.added;
  } else {
    return customizerData.yesMakeItAMeal;
  }
};

export const setIngredientOrder = (id, categoryID) => {
  switch (id) {
    case OMELETE_ID != categoryID && BREAD:
      return 1;
    case PASTA:
      return 2;
    case RICE:
      return 3;
    case PROTEIN:
      return 4;
    case EGG:
      return 5;
    case CHEESE:
      return 6;
    case VEGETABLE:
      return 7;
    case SAUCE:
      return 8;
    case SEASONINGS:
      return 9;
    case ADD_ONS:
      return 10;
    case EXTRAS:
      return 11;
    case OMELETE_ID === categoryID && BREAD:
      return 12;
    default:
      return 14;
  }
};

/**
 * add ellipsis based on the content
 * @param {*} ingredients selected ingredients names
 */
export function setEllipsis(ingredients, showLess) {
  if (ingredients.length > DEFAULT_NUMBER_OF_CHARACTERS && !showLess) {
    const expressions = `(.{${DEFAULT_NUMBER_OF_CHARACTERS}})..+`;
    const regex = new RegExp(expressions, 'g');
    return ingredients.replace(regex, '$1…');
  }
  return ingredients;
}

/**
 * filter combo for AddItemAsync API
 * @param {*} combos selected ingredients names
 */
export function setCompoForAPICall(combos) {
  const flatened = [].concat(...combos);
  return flatened.map(item => ({
    productId: !item.cookieProductId ? item.choiceId : item.cookieProductId,
    productName: item.name,
    imageUrl: item.image,
    options: item.modifierOptions
      ? item.modifierOptions
        .filter(optionItem => optionItem.isDefault === true)
        .map(optionItem => ({
          optionId: optionItem.id,
          optionName: `${item.withText} ${optionItem.name}`,
        }))
      : item.choiceId == item.value && !item.cookieProductId
        ? []
        : [
          {
            optionId: item.value,
            optionName: item.name,
            portion: 1,
          },
        ],
  }));
}

/**
 * Merge sidesAndDrinks property to products property
 * filter for GetIngredientsAsync API
 * @param {*} ingredients data from API call
 */
export function mergeSidesAndDrinks(dataFromApi) {
  const products_obj = dataFromApi.allProductsUnderMasterProduct[0].products[0];
  let translation_obj = dataFromApi.allProductsUnderMasterProduct[0].translations;
  const sides_and_drinks_obj = dataFromApi.allProductsUnderMasterProduct[0].sidesAndDrinks;
  sides_and_drinks_obj.isFountainDrink = true;
  const prod_desc = translation_obj[0].productDescription;

  if (
    products_obj.name !== FOUNTAIN_DRINK &&
    products_obj.name !== FOUNTAIN_BEVERAGE &&
    products_obj.name !== HOT_BEVERAGE &&
    products_obj.name.trim() !== 'Soups Sides' &&
    products_obj.name.trim() !== 'Soup'
  ) {
    products_obj.builds = { translations: {} };
    products_obj.builds.translations = sides_and_drinks_obj.translations;
    dataFromApi.allProductsUnderMasterProduct[0].translations = { ...translation_obj };
  }

  products_obj.translations = sides_and_drinks_obj.translations;
  translation_obj = { ...sides_and_drinks_obj.translations };
  translation_obj[0].productDescription = prod_desc;
  products_obj.sortOrder = sides_and_drinks_obj.sortOrder;
  products_obj.isDefault = sides_and_drinks_obj.isDefault;
  products_obj.isDefaultChangeable = sides_and_drinks_obj.isDefaultChangeable;
  products_obj.inStock = sides_and_drinks_obj.inStock;
  products_obj.nutritions = sides_and_drinks_obj.nutritions;
  products_obj.modifierGroups[0].options = products_obj.modifierGroups[0].options.filter(
    item => item.id == sides_and_drinks_obj.id,
  );
  products_obj.modifierGroups[0].options[0].selected =
    products_obj.name === FOUNTAIN_DRINK &&
      products_obj.name !== FOUNTAIN_BEVERAGE &&
      products_obj.name !== HOT_BEVERAGE
      ? false
      : true;

  dataFromApi.allProductsUnderMasterProduct[0].products[0] = { ...products_obj };
  dataFromApi.allProductsUnderMasterProduct[0].products.map(({ modifierGroups }) => {
    modifierGroups[0].invisible = true;
  });
  return dataFromApi;
}

export function checkForOptId() {
  const pathname = window.location.pathname.toLowerCase();
  let optId = null;
  const index = pathname.indexOf('optionid');
  if (index > 0) {
    var str = window.location.pathname.substr(index);

    optId = str.split('/')[1].toUpperCase();
  }
  return optId;
}

/**
 * add nutrition calculator
 * @param {*} ingredients selected nutrition values
 */
export const getNutritionCalculation = (ingredients, defaultNutrition, rules, customizerData, productBuildId) => {
  let result = [];
  let dataList = [];
  let hashResult = {};
  const nutritionList = [];

  ingredients && ingredients.length > 0
    ? ingredients.map(selectedData =>
      selectedData.options.map(optionList => {
        if (
          optionList.selected &&
          optionList.name != customizerData.doubleMeat &&
          optionList.name != customizerData.extraCheese &&
          optionList.name != customizerData.double &&
          optionList.name != customizerData.doubleProtien &&
          optionList.name != customizerData.deluxe
        ) {
          nutritionList.push(optionList.nutritions);
        }
      }),
    )
    : nutritionList.push(defaultNutrition);

  if (productBuildId === 53) { // sidekicks
    nutritionList.push(defaultNutrition);
  }

  if (nutritionList.length > 0) {
    //Grouping nested array into one array
    nutritionList.forEach(ingredientsData => {
      if (ingredientsData && ingredientsData.length > 0) {
        ingredientsData.forEach(listNutrition => {
          listNutrition.translations.forEach(name => {
            return dataList.push({
              value: parseFloat(listNutrition.value),
              id: listNutrition.id,
              name: name.displayName,
            });
          });
        });
      }
    });
  }

  if (dataList.length > 0) {
    // adding value from the loop based on id
    dataList.forEach(nutrition => {
      if (!hashResult[nutrition.id]) {
        hashResult[nutrition.id] = { id: nutrition.id, name: nutrition.name, value: 0 };
        result.push(hashResult[nutrition.id]);
      }
      hashResult[nutrition.id].value += nutrition.value;
    });
  }

  result &&
    result.length > 0 &&
    result.map(resultSetData => {
      if (rules && Object.keys(rules).includes(resultSetData.id)) {
        Object.values(rules).forEach(val => {
          if (resultSetData.id === val.nutritionFactId) {
            let percentageVal = (resultSetData.value / val.dvDivisor) * 100;
            let value =
              val.dvDivisor && resultSetData.value > 0 ? percentageVal : resultSetData.value;
            resultSetData.value = value > 0 ? calorieRoundingRules(value, val.ranges) : value;
            return resultSetData.value;
          }
        });
      } else {
        resultSetData.value = Math.round(resultSetData.value);
        return resultSetData.value;
      }
    });

  return result;
};


export const footerPadding = () =>
  document.querySelector('.component_footer').classList.add('footer_padding_btn');

/**
 * remove min 0 selected ingredients in Footer
 * @param {*} ingredients in footer
 */

export const clearAllMinValues = (productData, customizerData, selectedIndex, allTypes) => {
  productData.length > 0 &&
    productData.map((optionLists, index) => {
      let caloriesValues = 0;
      optionLists.isSelected = false;
      if (index === selectedIndex) {
        optionLists.isSelected = true;
        optionLists.ingredients.map(list => {
          if (list.min === 0) {
            list.options.map(listOption => {
              if (listOption.selected) {
                caloriesValues -= listOption.initialCalorie;
              }
              listOption.selected = false;
              if (
                listOption.isDefault &&
                !listOption.isDefaultChangeable
              ) {
                listOption.selected = true;
                caloriesValues += listOption.initialCalorie;
              }
              _get(listOption, ['additionalOptions', 'buttonNames'], []).map(button => {
                if (button.name === customizerData.regular) {
                  button.selected = true;
                  listOption.name = listOption.orginalName;
                  listOption.calories = listOption.initialCalorie;
                }
                button.selected = false;
              });
            });
          } else {
            list.options.map(listOption =>
              _get(listOption, ['additionalOptions', 'buttonNames'], []).map(button => {
                if (button.isDefault && button.inStock && button.name === customizerData.regular) {
                  button.selected = true;
                  listOption.name = listOption.orginalName;
                  listOption.calories = listOption.initialCalorie;
                }
                if (
                  (!button.isDefault && !button.inStock) ||
                  (button.isDefault && !button.inStock) ||
                  (!button.isDefault && button.inStock)
                ) {
                  button.selected = false;
                }
              }),
            );
          }
        });
        optionLists.selectionStateCalories += caloriesValues;
      } else {
        optionLists.ingredients = _cloneDeep(allTypes[index].ingredients);
        optionLists.combo = _cloneDeep(allTypes[index].combo);
        optionLists.selectionStateCalories = _cloneDeep(allTypes[index].selectionStateCalories);
      }
    });
  return productData;
};
export const setDefaultSelection = (
  selectedOptions,
  selectedIngredientID,
  modifierOptionsClick,
) => {
  const getButtonOptions = _get(selectedOptions, ['additionalOptions', 'buttonNames'], []);
  getButtonOptions.map(item => {
    if (item.isDefault) {
      modifierOptionsClick(
        null,
        item,
        selectedOptions.name,
        selectedOptions.id,
        selectedIngredientID,
        selectedOptions.orginalName,
        'RESET',
      );
      item.selected = true;
      return item.selected;
    }
    item.selected = false;
    return item.selected;
  });
};

export const editModeCalories = (currentSelectedItem, rules, customizerData) => {
  let protein = 0;
  let extraCheese = 0;
  let more = 0;
  let less = 0;
  const ingredients = _get(currentSelectedItem, ['0', 'ingredients'], null);
  ingredients &&
    ingredients.filter(list => {
      if (list.options) {
        list.options.filter(option => {
          if (multipleAndCheck(option.selected, option.name)) {
            if (
              multipleAndCheck(
                multipleOrCheck(
                  option.name.includes(customizerData.double),
                  option.name.includes(customizerData.doubleProtien),
                  option.name.includes(customizerData.doubleMeat),
                ),
                list.id === PROTEIN,
              )
            ) {
              protein = protein + option.calories / 2;
            } else if (multipleAndCheck(option.name.includes(customizerData.deluxe), list.id === PROTEIN)) {
              protein = protein + option.calories / 3;
            } else if (option.name.includes(customizerData.extraCheese)) {
              extraCheese = extraCheese + option.calories / option.more;
            } else if (multipleAndCheck(option.name.includes(customizerData.more), list.id != EXTRAS)) {
              const rounded = calorieRoundingRules(option.calories, rules);
              more = more + rounded / option.more;
            } else if (option.name.includes(customizerData.less)) {
              option.calories = calorieRoundingRules(option.calories, rules);
              less = less - option.calories;
            }
          }
        });
      }
    });
  return protein + extraCheese + more + less;
};

export const getDisplayOptionsFlag = (ingredientId, categoryId, isCookiePack) => {
  const ingredients = ['cookies'];
  if (categoryId == 847 || isCookiePack) {
    return true;
  }
  return !ingredients.includes(ingredientId.toLowerCase());
};

export const getCustomizerJSONData = () => {
  const customizerDataID = document.getElementById('customizer-json-values');
  if (customizerDataID && customizerDataID.value) {
    return JSON.parse(customizerDataID.value);
  }
  return null;
};

export const setPDPOverlay = () => {
  if (subwayScript.getCookie('pdpOverlay') === '') {
    subwayScript.setCookie('pdpOverlay', 'true', 90);
  }
};

export function getStoreIDByURL() {
  const url = window.location.href;
  let storeId = null;
  if (url.includes('restaurant')) {
    const quearyValues = url.substring(url.indexOf('restaurant')).split('/');
    storeId = quearyValues[1];
  } else {
    storeId = orderViewModel.store.StoreId;
  }
  return storeId;
}

export const onTabEsc = onButtonClick => {
  const close = e => {
    if (e.keyCode === 27) {
      onButtonClick();
    }
  };
  window.addEventListener('keydown', close);
  return () => window.removeEventListener('keydown', close);
};

export const getDaysInYear = () => {
  let now = new Date();
  let start = new Date(now.getFullYear(), 0, 0);
  let diff = now - start;
  let oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
};

export const updateOrderViewModel = (orderViewModel, responseData) => {
  if (responseData.cartId !== orderViewModel.currentOrder.cartId) {
    orderViewModel.cartOpen = true;
  }
  orderViewModel.currentOrder.cartId = responseData.cartId;
  orderViewModel.currentOrder.cartQuantity =
    responseData.cartItemQuantity || responseData.cartQuantity;
  orderViewModel.addCartProducts(
    responseData.cartItemId,
    responseData.productID,
    responseData.categoryId,
    '',
  );
  orderViewModel.save();
};

export const getIdFromURL = (identifierName) => {
  const identifierNameLcase = identifierName.toLowerCase();
  const url = window.location.pathname.toLowerCase();
  const remainingUrl = url.substring(url.indexOf(`${identifierNameLcase}/`));
  return parseInt(remainingUrl?.split('/')[1]);
};

let handleKeydown;

export const addFocusListener = (modalRef, firstFocusableElementRef, lastFocusableElementRef) => {
  if (
    _isEmpty(modalRef) ||
    _isEmpty(firstFocusableElementRef) ||
    _isEmpty(lastFocusableElementRef)
  ) {
    return;
  }
  const focusableElements =
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
  const focusableContent = modalRef.current.querySelectorAll(focusableElements);
  firstFocusableElementRef.current = focusableContent[0];
  lastFocusableElementRef.current = focusableContent[focusableContent.length - 1];

  handleKeydown = (e) => {
    const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElementRef.current) {
        lastFocusableElementRef.current.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableElementRef.current) {
        firstFocusableElementRef.current && firstFocusableElementRef.current.focus();
        e.preventDefault();
      }
    }
  };
  //for testing ios issue I changed document to modalRef.current
  modalRef.current.addEventListener('keydown', handleKeydown);
  firstFocusableElementRef &&
    firstFocusableElementRef.current &&
    firstFocusableElementRef.current.focus();
};

export const removeFocusListener = () => {
  document.removeEventListener('keydown', handleKeydown);

};



/**
 * get height
 */
export function getHeight(id) {
  const item = document.querySelector(id) || null;
  const height = _get(item, ['offsetHeight'], '0');
  return height + 'px';
}

export const getCategoryAndProductID = (isNonTransactional = false) => {
  let values = {};
  const url = window.location.href;
  if (url.includes('customizer')) {
    const val = url.substring(url.toLowerCase().indexOf('productsummary')).split('/');
    if (val.length > 0) {
      values.productSummary = val[1];
      let categoryId = url.includes('item') ? val[12] : val[3];
      values.category = categoryId?.includes('?') ? categoryId.split('?')[0] : categoryId;
    }
  }
  if (isNonTransactional) {
    const index = url.split('/').indexOf('product');

    values.productSummary = index > 0 ? url.split('/')[index + 1] : 0;
  }
  return values;
};

export const getUrlFlagId = (flag) => {
  const url = window.location.href.toLowerCase();
  const queryParam = flag?.toLowerCase();
  if (url.includes(queryParam)) {
    const newUrl = new URL(url);
    const value = newUrl.searchParams.get(queryParam);
    return value;
  }
}

export const getCartIDAndCartItemId = () => {
  let values = {};
  const url = window.location.href;
  if (url.includes('cart')) {
    const val = url.substring(url.indexOf('cart')).split('/');
    if (val.length > 0) {
      values.cartID = val[1];
      values.cartItemID = val[3];
    }
  }
  return values;
};

export const makeItAMeal = () => {
  let values = {};
  const url = window.location.href;
  if (url.includes('make-it-a-meal')) {
    const val = url.substring(url.indexOf('make-it-a-meal')).split('/');
    if (val.length > 0) {
      const urlValue = val[1].split('?');
      const remaining = urlValue[1].split('&');
      values.productId = urlValue[0];
      values.referrer = remaining[0].split('=')[1];
      values.cartId = remaining[1] ? remaining[1].split('=')[1] : '';
      values.cartItemId = remaining[2] ? remaining[2].split('=')[1] : '';
    }
  }
  return values;
};
export const rootHtmlElementScroll = value => {
  document.getElementsByClassName('cms')[0].style.overflow = value ? 'initial' : 'hidden';
};

export const formatDate = inputDate => {
  const date_split_arr = inputDate.split('-');
  return [date_split_arr[1], date_split_arr[2], date_split_arr[0].substring(2)].join('/');
};

export const formatEpochDate = inputDate => {
  const date = new Date(inputDate);
  const year = date.getUTCFullYear().toString().slice(-2);
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  return orderViewModel.headerFooterLanguageCode == 'fr-CA'
    ? `${day}/${month}/${year}`
    : `${month}/${day}/${year}`;
};

/**
 * To get the url for start order
 */
export const getStartOrderUrl = () => {
  if (orderViewModel.store.id) {
    return (
      BASE_URL +
      subwayScript.navigation.getLanguageCode() +
      '/restaurant/' +
      orderViewModel.store.id +
      '/menu'
    );
  }
  return BASE_URL + subwayScript.navigation.getLocatorUrl();
};

export const deliveryTimeEstimationAsync = function (place, item, fetchDealAvailable, btnId = null, setContextLoader = null) {
  let address = {
    StreetAddressLine1: place.StreetAddressLine1,
    City: place.City,
    State: place.State,
    Zip: place.Zip,
    Country: place.Country,
    StoreId: orderViewModel.store.id ? orderViewModel.store.id : null,
    SiteName: $('#hdnSiteName').val(),
    PromoID: ShopPromotionFlag ? place.promoId : '',
    promoCode: ShopPromotionFlag ? place.promoCode : '',
    offerPLU: ShopPromotionFlag ? place.offerPLU : '',
    cartId: orderViewModel.currentOrder.cartId ? orderViewModel.currentOrder.cartId : null,
  };
  if ($('#subwayDeliveryLocator-modalpopup').length === 0) {
    setTimeout(function () {
      var subwaySpinner = $('#subway-spinner');
      if (subwaySpinner.css('display') != 'none') {
        $('#subway-spinner #spinner-text').css('display', 'block');
      }
    }, 2000);
  }
  $.ajax({
    url: $('#DeliveryTimeEstimation').attr('action'),
    type: 'POST',
    data: address,
    success: function (data) {
      if (btnId) {
        setContextLoader({
          buttonId: btnId,
          isLoading: false
        });
      }
      if (data.storeId && orderViewModel.store.id != data.storeId) {
        orderViewModel.store.id = data.storeId;
        orderViewModel.store.StoreId = data.storeId;
        orderViewModel.save();
      }
      $('#subway-spinner').hide();
      if (item.cardType.toLowerCase() !== 's&d' && item.cardType.toLowerCase() !== 'non s&d') {
        orderViewModel.promosAndDeals.isPromoCodeApplied = true;
      }
      orderViewModel.cardType = place.cardType || '';
      orderViewModel.save();
      if (item.loyaltyDetail) {
        let locationObject = {
          offerId: ShopPromotionFlag ? item.loyaltyDetail.offerId : '',
          propositionGuid: item.loyaltyDetail.propositionGuid,
          offerStatus: item.loyaltyDetail.offerStatus,
          startDate: item.startDate,
          expirationDate: item.expirationDate,
        };
        let dealsAPIObject = {
          OfferId: ShopPromotionFlag ? item.loyaltyDetail.offerId : '',
          OfferCampaignUri: item.loyaltyDetail.offerCampaignUri,
          OfferDescription: item.titleDisplayText,
          RewardExpirationDate: item.expirationDate,
          RewardType: item.cardType,
          OfferUri: item.loyaltyDetail.propositionGuid,
          offerStatus: item.loyaltyDetail.offerStatus,
          startDate: item.startDate,
        };
        let deliveryAddress = {
          StreetAddressLine1: orderViewModel.DeliveryAddress.StreetAddressLine1,
          StreetAddressLine2: orderViewModel.DeliveryAddress.StreetAddressLine2,
          State: orderViewModel.DeliveryAddress.State,
          City: orderViewModel.DeliveryAddress.City,
          Country: orderViewModel.DeliveryAddress.Country,
          Zip: orderViewModel.DeliveryAddress.Zip,
          QuoteId: orderViewModel.DeliveryAddress.QuoteId,
        };
        if (ShopPromotionFlag == 'true') {
          if (
            orderViewModel.store &&
            orderViewModel.store.id &&
            orderViewModel.orderType == 'delivery'
          ) {
            fetchDealAvailable(dealsAPIObject, locationObject, deliveryAddress);
          } else {
            const deepLink = subwayScript.navigation.getLocatorUrl();
            window.location.href = deepLink + '?offer=' + JSON.stringify(locationObject);
          }
        } else {
          if (orderViewModel.store && orderViewModel.store.id) {
            fetchDealAvailable(dealsAPIObject, locationObject, {});
          } else {
            const deepLink = subwayScript.navigation.getLocatorUrl();
            window.location.href = deepLink + '?offer=' + JSON.stringify(locationObject);
          }
        }
      } else {
        orderViewModel.showRedirectSuccessBanner = true;
        orderViewModel.redirectBannerMessage = Congrats;
        orderViewModel.save();
        window.location.href = subwayScript.navigation.getMenuUrl(orderViewModel.store.id);
      }
    },
    error: function (xhr, status, error) {
      if (btnId) {
        setContextLoader({
          buttonId: btnId,
          isLoading: false
        });
      }
      $('#subway-spinner').hide();
      $('#modal-store-closed .storeClosedModal-content button.deals-error-modal-btn')
        .closest('.proceedWithDelivery')
        .remove();
      $('#modal-store-closed .storeClosedModal-content .head-txt').text(DealNotAvailable);
      $('#modal-store-closed .storeClosedModal-content .body-txt').text(
        CurrentLocationNotApplicableDeal,
      );
      $('#modal-store-closed .storeClosedModal-content button.deals-error-modal-btn').text(
        SwitchtoPickup,
      );
      $('#modal-store-closed .storeClosedModal-content button.deals-error-modal-btn').prop(
        'id',
        'switch-to-pickup',
      );
      $('#switch-to-pickup').prop('onclick', null);
      $('#modal-store-closed .storeClosedModal-content button.deals-error-modal-btn').after(
        '<button class="btn w-100 deals-error-modal-btn btn-border proceedWithDelivery" >' +
        CancelKeepDelivery +
        '</button>',
      );
      $('#modal-store-closed .storeClosedModal-content .proceedWithDelivery').attr(
        'id',
        'proceedWithDelivery',
      );
      $('#modal-store-closed').show();
      modalShowId('#modal-store-closed', true);
      $('#modal-store-closed').find('.error-title').text(xhr.responseJSON.error.title);
      $('#modal-store-closed').find('.error-body').text(xhr.responseJSON.error.message);
      let title =
        $('#modal-store-closed').find('.error-title').text() ||
        $('#modal-store-closed').find('.head-txt').text();
      analytics.dealErroModal(title);
      $('#proceedWithDelivery').click(function (event) {
        applyDancingBubble(event, 'gray', true)
        analytics.dealPromoCTA($('#proceedWithDelivery').text().toLowerCase());
        window.location.href = subwayScript.navigation.getMenuUrl(orderViewModel.store.id);
      });
      $('#switch-to-pickup').click(function (event) {
        analytics.dealPromoCTA($('#switch-to-pickup').text().toLowerCase());
        applyDancingBubble(event, 'white', true);
        if (item.loyaltyDetail) {
          const locationObject = {
            offerId: ShopPromotionFlag ? item.loyaltyDetail.offerId : '',
            propositionGuid: item.loyaltyDetail.propositionGuid,
            offerStatus: item.loyaltyDetail.offerStatus,
            startDate: item.startDate,
            expirationDate: item.expirationDate,
          };
          setTimeout(() => {
            window.location.href =
              subwayScript.navigation.getLocatorUrl() + '?offer=' + JSON.stringify(locationObject);

          }, 500);
        } else {
          const promoURL =
            '?promoPLU=' +
            item.promoPLU +
            '&promoId=' +
            item.promoId +
            '&promoCode=' +
            item.promoCode;
          setTimeout(() => {
            window.location.href =
              subwayScript.navigation.getLocatorUrl() +
              (item.promoId && item.promoCode ? promoURL : '');
          }, 500);
        }
      });
    },
  });
};

export const phoneNumberFormatter = inputValue => {
  const digitsArray = inputValue.replace(/\D/g, '').match(DIGIT_PARTITION_ARRAY);
  const digitArray3 = digitsArray[3] ? '-' + digitsArray[3] : '';
  const formattedInputValue = !digitsArray?.[2]
    ? digitsArray?.[1]
    : `(${digitsArray?.[1]}) ${digitsArray?.[2]}${digitArray3}`;
  const userInput = digitsArray[0];
  return [userInput, formattedInputValue];
};

/**
 * Add dynamic value to the string. Eg. If the string from sitecore is "Add {0} points", then this
 * function can replace the {0} with dynamic values
 * @param {*} inputString the static string
 * @param {*} value value to replace the {0}
 * @returns replaced string
 */
export const addDynamicValueToString = (inputString, value) => {
  return !isEqual(value, 0)
    ? inputString.replace('{0}', `${value}`)
    : inputString.replace('{0}', '');
};

export const ingredientDisplayName = (currentSelectedItem, productData, name) => {
  if (
    _get(currentSelectedItem, ['0', 'ingredients', '0', 'id'], '') === COOKIES &&
    currentSelectedItem[0].categoryId != COOKIE_PACK &&
    !_get(productData, ['0', 'isShowSize'])
  ) {
    return _get(currentSelectedItem, ['0', 'ingredients', '0', 'displayName'], '');
  }
  if (_get(productData, ['0', 'isShowSize'])) {
    return _get(currentSelectedItem, ['0', 'name'], '') + ' ' + name;
  }
  if (currentSelectedItem[0].categoryId == FOUNTAIN_DRINK_ID) {
    return _get(currentSelectedItem, ['0', 'name'], '');
  }
  if (currentSelectedItem[0].categoryId == 969) {
    return name;
  }
  if (_get(productData, ['0', 'isSideOrDrink'])) {
    return name;
  }
  return _get(currentSelectedItem, ['0', 'name'], '') + ' ' + name;
};

const fetchDealAvailableAtStore = (dealsAPIObject, locationObject, deliveryAddress) => {
  subwayScript.showSpinner();
  orderViewModel.selectedOffer = dealsAPIObject;
  orderViewModel.save();
  axios({
    method: 'POST',
    url: window.location.origin + '/RemoteOrder/Orders/DealAvailableAtStore',
    data: JSON.stringify({
      cartId: orderViewModel.currentOrder.cartId,
      storeId: orderViewModel.store.id,
      Offers: [dealsAPIObject],
      DeliveryAddress: deliveryAddress,
      QuoteId: deliveryAddress.QuoteId,
    }),
    headers: {
      //   'Authorization': 'Bearer ' + idToken ,
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then(response => {
      orderViewModel.selectedOffer.confirmedValid = true;
      orderViewModel.showRedirectSuccessBanner = true;
      orderViewModel.redirectBannerMessage = Congrats;
      orderViewModel.save();
      const redirectURL = subwayScript.navigation.getMenuUrl(orderViewModel.store.id);
      if (response.data.NotOffered) {
        subwayScript.hideSpinner();
        modalClose();
        setShowModal(true);
      } else {
        const voucherQueryModel = {
          Offers: [
            {
              propositionGuid: locationObject.PropositionGuid,
            },
          ],
          storeId: orderViewModel.store.id,
          country:
            orderViewModel.orderType == 'delivery'
              ? orderViewModel.DeliveryAddress.Country
              : orderViewModel.store.storeCountry,
        };
        const validateOfferUrl = '/api/sitecore/Offers/GetVoucherInquiryAsync';
        loyaltyObject.validateVoucherAndApplyOffer(voucherQueryModel, validateOfferUrl, true);
        window.location.href = redirectURL;
      }
    })
    .catch(error => {
      console.log('error :');
      console.log(error);
      orderViewModel.selectedOffer = null;
      orderViewModel.save();
      subwayScript.hideSpinner();
    });
};

export const getDealsType = ({ dealsList }) => {
  return !_isEmpty(dealsList) ? dealsList[0].cardType : null;
};

export const getCardLayoutType = deviceWidth => {
  return deviceWidth <= 600 ? 'regular' : 'small';
};

export const isNationalDeal = ({ cardType }) => {
  return isEqual(cardType, DEAL_NATIONAL) || isEqual(cardType, NON_BONUS) || isEqual(cardType, BONUS);
};

export const isNonSdDeal = ({ cardType }) => {
  return isEqual(cardType, DEAL_NON_SD);
};

export const isSdDeal = ({ cardType }) => {
  return isEqual(cardType, DEAL_SD);
};

export const isSubwayMerch = ({ cardType }) => {
  return isEqual(cardType, DEAL_SUBWAYMERCH);
};

export const prepareComboData = arr => {
  const finalData = [];

  arr.map(combo => {
    const cardId = _get(combo, ['masterPromotionId'], null) || _get(combo, ['optionId'], null);
    finalData.push({
      id: cardId,
      masterPromotionId: cardId,
      name: combo.translations[0].displayName,
      description: combo.translations[0].displayDescription,
      price: _get(combo, ['prices', '0', 'price'], 0),
      calorie: combo.maxNutrition,
      imageUrl: combo.translations[0].imageUrl,
      inStock: true,
      isSelected: true,
      iconButton: true,
      isAddtoCart: combo.isAddtoCart ? combo.isAddtoCart : false,
      isUpsell: combo.hasCombo === false ? true : false,
      hasCombo: combo.hasCombo === false ? false : true,
      productId: combo.productId,
      categoryName: combo.promotionTypeName ? combo.promotionTypeName : combo.categoryName,
      categoryID: _get(combo, ['categoryId'], null),
      isUpdated: false,
    });
  });

  return finalData;
};

export const extraSlides = (isQuickAddOns, windowWidth) => {
  if (isQuickAddOns) {
    return windowWidth <= 600 ? 0.12 : 0.3;
  }
  return windowWidth <= 600 ? 0.06 : 0.12;
};

export const calculateSlidesToShow = (
  isQuickAddOns,
  isTablet,
  slideCount,
  extraSlide,
  windowWidth,
  oneViewPerSlide,
  isFavoriteOrBestseller,
  fromModalCarousel,
) => {
  let slideCounts = slideCount + extraSlide;
  if ((isQuickAddOns && windowWidth <= 600) || fromModalCarousel) {
    slideCounts = 1 + extraSlide;
  }
  if (isFavoriteOrBestseller && windowWidth <= 600) {
    slideCounts = 2 + extraSlide;
  }
  if (isTablet && windowWidth <= 1024 && windowWidth >= 667) {
    slideCounts = 2 + extraSlide;
  }
  if (oneViewPerSlide) {
    slideCounts = 1;
  }
  return slideCounts;
};

export const calculateExtraSlide = (isQuickAddOns, windowWidth, noExtraSlideView, fromModalCarousel) => {
  if (fromModalCarousel) {
    return 0.3;
  }
  if (isQuickAddOns) {
    return windowWidth <= 600 ? 0.2 : 0.4;
  }
  if (noExtraSlideView) {
    return 0;
  }
  return windowWidth <= 600 ? 0.06 : 0.12;
};

export const ingredientItemList = (selectedIngredient, button_price, customizerData, list) => {
  const selectedOptionList = [
    selectedIngredient.orginalNmae,
    customizerData.double,
    customizerData.doubleProtien,
    customizerData.doubleMeat,
    customizerData.deluxe,
  ];
  const listData = list.toString();

  if (!selectedIngredient.name) {
    return '';
  }

  let result = selectedIngredient.name.trim();

  if (button_price && button_price.length > 0) {
    if (
      !selectedOptionList.some(optList => listData.includes(optList)) &&
      !result.includes(customizerData.extraCheese)
    ) {
      result = `(${button_price[0].name}), ${result
        .replace(customizerData.double, '')
        .replace(customizerData.doubleProtien, '')
        .replace(customizerData.doubleMeat, '')
        .replace(customizerData.deluxe, '')}`;
    } else if (result.includes(customizerData.extraCheese)) {
      result = `${result} +${currencyPlacement('$', button_price[0].price.toFixed(2))} `;
    } else {
      result = result
        .replace(customizerData.double, '')
        .replace(customizerData.doubleProtien, '')
        .replace(customizerData.doubleMeat, '')
        .replace(customizerData.deluxe, '');
    }
  }

  return result;
};

export const formAction = (url, token) => {
  const form = document.createElement('form');
  form.action = url;
  form.method = 'post';
  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = 'CheckoutToken';
  input.value = token;
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
};

export const getHeaderTitle = (categoryId, isKidsMeal, translations, sideName) => {
  if (categoryId != ULTIMATE_BREAKFAST) {
    if (isKidsMeal) {
      return translations.kidsMeal;
    }
    return translations.yourCombo;
  }
  return sideName;
};

export const getComboDescription = (
  comboPageCounter,
  isUltimateBreakfast,
  translations,
  isBAUPdp,
) => {
  if (isBAUPdp) {
    if (comboPageCounter == 0 && isUltimateBreakfast) {
      return translations.selectBottledOrFountainDrink;
    }
    if (isUltimateBreakfast && comboPageCounter > 0) {
      return translations.selectKidsSides;
    }
    if (!isUltimateBreakfast && comboPageCounter == 0) {
      return translations.selectBottledOrFountainDrink;
    }
    if (!isUltimateBreakfast && comboPageCounter > 0) {
      return translations.sideOfSelectOne;
    }
  }
  return comboPageCounter == 0 && !isUltimateBreakfast
    ? translations.selectBottledOrFountainDrink
    : translations.sideOfSelectOne;
};

export const cartComboName = (isBAUPdp, item, itemsData, dictionaryLables) => {
  if (isBAUPdp) {
    if (item.isMeal && !item.isKidsMeal) {
      return itemsData.makeItAMeal;
    }
    if (item.isKidsMeal) {
      return item.mealText;
    }
    return `${itemsData.makeItAMealFor}`;
  }
  return item.isMeal ? item.mealText : itemsData.makeItAMealFor;
};

export const showButtonEnabled = (currentSelectedItem, buttonEnabled) => {
  const ingredients = currentSelectedItem[0]?.ingredients
  if (
    multipleAndCheck(
      !_isEmpty(currentSelectedItem),
      ingredients?.length !== 0,
      multipleOrCheck(
        currentSelectedItem[0]?.categoryId == 847,
        currentSelectedItem[0]?.categoryId == 969,
      ),
    )
  ) {
    if (!buttonEnabled) {
      return false;
    }
    return true;
  }
  return true;
};

export const handleEmptyCartRedirection = isStoreSelected => {
  let url = subwayScript.navigation.getLocatorUrl();
  if (isStoreSelected) {
    url = subwayScript.navigation.getMenuUrl(orderViewModel.store.id);
  }
  window.location.href = url;
};

export const replaceMultiple = (str, replacements) => {
  return replacements
    .reduce((acc, [searchValue, replaceValue]) => acc.replaceAll(searchValue, replaceValue), str)
    .trim();
};

const selectedButton = (inStock, selectedBtn, isToasted, isGrilled, name, customizerData) => {
  if (inStock && isToasted) {
    return selectedBtn;
  }
  if (inStock && isGrilled) {
    return selectedBtn;
  }
  if (name == customizerData.toasted && !isToasted) {
    return false;
  }
  if (name == customizerData.grilled && !isGrilled) {
    return false;
  }
  return false;
};

export const ingredientTitle = item => {
  return !_isEmpty(item.translations) && !_isEmpty(item.translations[0].displayName)
    ? item.translations[0].displayName
    : item.id;
};

export const getFooterDisplayName = (currentType, productData) => {
  if (_get(currentType, ['0', 'ingredients', '0', 'id'], '') === COOKIES) {
    if (_get(currentType, ['0', 'categoryId'], '') == COOKIE_PACK) {
      return _get(currentType, ['0', 'name'], '');
    }
    return _get(currentType, ['0', 'ingredients', '0', 'displayName'], '');
  } else {
    if (productData[0].isShowSize) {
      return currentType[0].name;
    } else if (
      !productData[0].isShowSize &&
      currentType &&
      currentType[0].unTranslatedName === 'Soup'
    ) {
      return currentType.find(data => data.isSelected)?.name || '';
    }
    return '';
  }
};

export const getRole = (min, max) => {
  let role = 'checkbox';
  if (min == max || (min > 0 && max > min) || (min === 0 && max === 1)) role = 'radiogroup';
  return role;
};

export const getPrice = (price, priceSchemaId) => {
  const priceDetails = !_isEmpty(price) && price.find(item => item.pricingScheme === priceSchemaId);
  return !_isEmpty(priceDetails) && priceDetails.price;
};

/**
 * Checks if any of the given values are present in the current URL
 * @param {Array} values - array of values to match
 * @returns {Boolean} true if any of the values are found, false otherwise
 */
export const matchUrl = values => {
  const pathSegments = window.location.pathname.split('/');

  return values.some(value => {
    return pathSegments.includes(value) && !pathSegments.includes('rewards-activity');
  });
};

export const toastBannerAnalytics = (
  setAnalyticsProducts,
  setAttemptTracker,
  addItemMessage = '',
) => {
  setAnalyticsProducts({
    bannerMessage: addItemMessage,
    bannerMessageEvent: 1,
    componentTitle: 'header',
    clickActionName: 'banner impression',
  });
  setAttemptTracker([{ name: 'banner_notification_impression' }]);
};

export const dismissBannerAnalytics = (
  setAnalyticsProducts,
  setAttemptTracker,
  dismissFlag = false,
) => {
  const attemptTrackIdentifier = 'banner_notification_dismiss';
  setAnalyticsProducts({
    bannerDismiss: dismissFlag ? 1 : 0,
    componentTitle: 'header',
    clickActionName: 'banner impression',
  });
  setAttemptTracker([{ name: attemptTrackIdentifier }]);
};

export const flattenArrayOfObjects = input => {
  if (Array.isArray(input)) {
    return input.flatMap(element => {
      if (Array.isArray(element)) {
        return flattenArrayOfObjects(element);
      }
      return element;
    });
  }
  return [input];
};

export const prepareErrorMessage = (flags, items, errorDictionary) => {
  let unavailableItems;
  if (flags.isIngredientWarning) {
    unavailableItems = items
      ?.filter(item => item.noIngredients && item.noIngredients.length > 0)
      ?.map(
        item => `${item.name}: ${commaSeparated(item.noIngredients)} ${errorDictionary.outofStock}`,
      );
  }

  if (
    (flags.hasAllOutStockProducts || flags.hasSomeOutStockProducts) &&
    !flags.isIngredientWarning
  ) {
    unavailableItems = flags.outStockProducts.map(item => `-${item.outStockProduct}`);
  }

  return unavailableItems;
};

export const getCartItemName = (item, options) => {
  if (item.isFountainDrink) {
    return options.toString();
  }
  if (!_isEmpty(item.analytics) && !item.analytics.isCookie) {
    return item.name;
  }
  if (!_isEmpty(item.analytics) && item.analytics.isCookie) {
    return `${item.name} - ${options}`;
  }
  return item.name;
};

export const selectedComboListData = selectedCombo => {
  let list = [];
  selectedCombo.map(listItems => {
    if (listItems.buttonData && listItems.buttonData.length > 0) {
      listItems.buttonData.map(button => {
        if (button.id == 'more' && button.selected) {
          list.push(listItems);
        }
      });
    }
    list.push(listItems);
  });
  return list;
};

export const convertKeysToLowerCase = data => {
  if (Array.isArray(data)) {
    return data.map(obj => convertKeysToLowerCase(obj));
  } else if (typeof data === 'object' && data !== null) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        acc[lowerCaseKey] = convertKeysToLowerCase(value);
      } else if (Array.isArray(value)) {
        acc[lowerCaseKey] = value.map(elem => convertKeysToLowerCase(elem));
      } else {
        acc[lowerCaseKey] = value;
      }
      return acc;
    }, {});
  } else {
    return data;
  }
};

export const checkForChanges = (oldArray, newArray) => {
  let changes = false;

  if (oldArray && oldArray.length > 0 && newArray && newArray.length > 0) {
    oldArray.forEach(oldItem => {
      const newItem = newArray.find(item => item.id === oldItem.id);

      if (newItem) {
        // Iterate over each option in the item
        oldItem.options.forEach(oldOption => {
          const newOption = newItem.options.find(option => option.id === oldOption.id);

          if (newOption && newOption.selected !== oldOption.selected) {
            changes = true;
          }
        });
      }
    });
  }
  return changes;
};
export const getPromoUrl = (promoPLU, promoId, promoCode, intcmp = '') => {
  const url = getLocatorUrl();
  const promoURL =
    '?promoPLU=' + promoPLU + '&promoId=' + promoId + '&promoCode=' + promoCode + intcmp;
  return url + (promoId ? promoURL : '');
};
export const getOfferUrl = (locationObject = {}) => {
  return getLocatorUrl() + '?offer=' + JSON.stringify(locationObject);
};
export const getPickupLocatorUrl = deal => {
  const intcmp = _get(deal.cardDetailsCTA, ['web', '0', 'intCmp'], '');
  const cmp = intcmp ? '&intCmp=' + intcmp : '';
  const locationObject = deal.loyaltyDetail && getLocationObject(deal);
  if (isEqual(deal.cardType, BONUS)) {
    return getLocatorUrl();
  }
  return isEqual(deal.cardType, DEAL_NATIONAL)
    ? getPromoUrl(deal.promoPLU, deal.promoId, deal.promoCode, cmp)
    : getOfferUrl(locationObject);
};
export const signupInvitation = () => {
  var $signupInvForm = $('#frmSignupInvitation');
  subwayScript.showSpinner();
  let language = ($('#scLanguageCode') && $('#scLanguageCode').val()) || 'en-us';
  $.ajax({
    url: $signupInvForm.attr('action'),
    type: 'POST',
    data: $signupInvForm.serialize(),
    success: function (data) {
      if (data != '') {
        window.location.href =
          '/' + language + '/SignUpInvitation?url=' + window.location.href + '&invitation=' + data;
      } else {
        window.location.href = '/' + language + '/SignUp';
      }
    },
    error: function (jqXhr) {
      window.location.href = '/' + language + '/SignUp';
    },
  });
};

export const getDealButtonText = (dealDetails, dictionaryLables) => {
  const checkReddemedOffer =
    _get(orderViewModel, 'redeemedOffers', []).indexOf(dealDetails.id) >= 0;
  if (checkReddemedOffer) {
    return dictionaryLables.redeemed;
  }
  if (dealDetails.cardType == DEAL_NATIONAL) {
    return orderViewModel.orderType == DELIVERY
      ? dictionaryLables.shopDeal
      : dictionaryLables.viewParticipatingLocation;
  }
  if (dealDetails.cardType == DEAL_SD) {
    return dictionaryLables.addToBag;
  }
  return _get(dealDetails, ['cardDetailsCTA', 'web', '0', 'displayText'], '');
};

export const redirectUrlsForDealModal = (actionType = '', dealDetails = {}) => {
  switch (actionType) {
    case KEEPDELIVERY:
    case CONTINUEORDER:
      analytics.dealPromoCTA(KEEPDELIVERY ? KEEP_DELIVERY : CONTINUE_WITH_ORDER);
      window.location.href = subwayScript.navigation.getMenuUrl(orderViewModel.store.id);
      return window.location.href;
    case FINDNEWLOCATION:
      window.location.href =
        getLocatorUrl() +
        '?zip=' +
        encodeURIComponent(orderViewModel.store.address + ' ' + orderViewModel.store.city);
      break;
    case SWITCHTOPICKUP:
      analytics.dealPromoCTA(SWITCH_TO_PICKUP);
      navigateToLocatorWithDealPickup(dealDetails);
      break;
    case VIEW_PARTICIPATING_LOCATION:
      analytics.dealPromoCTA(VIEW_PARTICIPATING_LOCATION);
      navigateToLocatorWithDealPickup(dealDetails);
      break
    case CONTINUE_WITHOUT_DEAL:
      analytics.dealPromoCTA(CONTINUE_WITHOUT_DEAL);
      navigateToMenu();
      break
    default:
      break;
  }
  return '';
};

export const getLocationObject = dealDetails => {
  return {
    offerId: _get(dealDetails, ['loyaltyDetail', 'offerId'], ''),
    propositionGuid: _get(dealDetails, ['loyaltyDetail', 'propositionGuid'], ''),
    offerStatus: _get(dealDetails, ['loyaltyDetail', 'offerStatus'], ''),
    startDate: _get(dealDetails, ['startDate'], ''),
    expirationDate: _get(dealDetails, ['expirationDate'], ''),
  };
};
export const getDeliveryAddressObject = () => {
  return {
    StreetAddressLine1: _get(orderViewModel, ['DeliveryAddress', 'StreetAddressLine1'], ''),
    StreetAddressLine2: _get(orderViewModel, ['DeliveryAddress', 'StreetAddressLine2'], ''),
    State: _get(orderViewModel, ['DeliveryAddress', 'State'], ''),
    City: _get(orderViewModel, ['DeliveryAddress', 'City'], ''),
    Country: _get(orderViewModel, ['DeliveryAddress', 'Country'], ''),
    Zip: _get(orderViewModel, ['DeliveryAddress', 'Zip'], ''),
    QuoteId: _get(orderViewModel, ['DeliveryAddress', 'QuoteId'], ''),
  };
};

export const getDealApiObject = dealDetails => {
  return {
    OfferId: _get(dealDetails, ['loyaltyDetail', 'offerId'], ''),
    OfferCampaignUri: _get(dealDetails, ['loyaltyDetail', 'offerCampaignUri'], ''),
    OfferDescription: _get(dealDetails, ['titleDisplayText'], ''),
    RewardExpirationDate: _get(dealDetails, ['expirationDate'], ''),
    RewardType: _get(dealDetails, ['cardType'], ''),
    OfferUri: _get(dealDetails, ['loyaltyDetail', 'propositionGuid'], ''),
    offerStatus: _get(dealDetails, ['loyaltyDetail', 'offerStatus'], ''),
    startDate: _get(dealDetails, ['startDate'], ''),
  };
};

export const getDeeplinkUrl = deal => {
  return _get(deal.cardDetailsCTA.web[0], ['deeplink'], '').split('subway://link/')[1];
};

export const getLocatorUrl = () => {
  return orderViewModel.orderType == DELIVERY
    ? subwayScript.navigation.getDeliveryLocatorUrl()
    : subwayScript.navigation.getLocatorUrl();
};

export const provideCardCTAText = (item, cardDetailsCTA) => {
  const promoId = item.promoId;
  const cardType = item.cardType;
  if (cardType == BONUS &&
    !promoId &&
    cardDetailsCTA.length > 0 &&
    cardDetailsCTA[0].deeplink !== '' &&
    cardDetailsCTA[0].storeSelector
  ) {
    return cardDetailsCTA[0].displayText;
  }
  if (
    promoId &&
    cardDetailsCTA.length > 0 &&
    cardDetailsCTA[0].deeplink !== '' &&
    !cardDetailsCTA[0].storeSelector
  ) {
    return cardDetailsCTA[0].displayText;
  }
  if (
    promoId &&
    cardDetailsCTA.length > 0 &&
    cardDetailsCTA[0].deeplink !== '' &&
    cardDetailsCTA[0].storeSelector
  ) {
    return cardDetailsCTA[0].displayText;
  }
  if (cardType == NON_BONUS || cardType == DEAL_NON_SD) {
    return cardDetailsCTA[0].displayText;
  }
  return null;
};

export const getParamFromURL = cartId => {
  const url = window.location.href.toLowerCase();
  const urlParams = new URL(url);
  return urlParams.searchParams.get(cartId);
};

export const prepareAddressForMap = addressData => {
  const store = orderViewModel.store || {};
  const { storeAddress1 = '', city = '', state = '', country = '' } = addressData;

  const addressSection = store.address || storeAddress1;
  const addressCity = store.city || city;
  const addressState = store.stateProvCode || state;
  const addressCountry = store.storeCountry || country;

  return _isObject(addressData)
    ? `${addressSection} ${addressCity} ${addressState} ${addressCountry}`
    : addressData;
};

export const getGoogleKey = () => {
  return window.locatorConfig && window.locatorConfig.getMyMapsKey();
};

export const navigationToFavorites = () => {
  window.location.href = window.subwayScript.navigation.getMyFavoritesUrl();
};

export const getItemClickedEvent = (isRewards, isMeal, type, itemsData, combolength) => {
  if (isRewards) {
    return type == 'CTA' ? itemsData.remove : REMOVE;
  }
  if (isMeal) {
    return type == 'CTA' ? itemsData.edit : EDIT;
  }
  return type == 'CTA' ? combolength > 1 ? itemsData?.choose : itemsData?.apply : APPLY
};

export const showStartOrderCTA = () => {
  const viewBagButton = document.querySelector('.view_bag_button');
  const startOrder = document.querySelector('.button.start_order.hide');
  const fullCartIcon = document.querySelector('.cart_bag.full');
  const emptyCartIcon = document.querySelector('.cart_bag.empty');

  if (viewBagButton) {
    viewBagButton.classList.replace('show', 'hide');
  }
  if (startOrder) {
    startOrder.classList.replace('hide', 'show');
  }
  if (fullCartIcon) {
    fullCartIcon.style.display = 'none';
  }
  if (emptyCartIcon) {
    emptyCartIcon.style.display = 'flex';
  }
};

export const clearOrderViewModel = orderViewModel => {
  orderViewModel.currentOrder.cartId = null;
  orderViewModel.currentOrder.cartQuantity = 0;
  orderViewModel.currentOrder.offers = {};
  orderViewModel.currentOrder.sidesDrinksQuantity = 0;
  orderViewModel.currentOrder.product.productId = null;
  orderViewModel.currentOrder.product.options = [];
  orderViewModel.currentOrder.itemAddedtoBag = 0;
  delete orderViewModel.currentOrder.productName;
  orderViewModel.cartProducts = [];
  orderViewModel.anlyticsCartProducts = [];
  orderViewModel.save();
};

/**
 * Prepares payload for add to bag API
 * @param {Object} orderViewModel - orderViewModel object
 * @param {String} addItemAsyncApi - API endpoint
 * @param {Object} item - item object
 * @param {String} siteName - site name
 * @returns {Object} payload
 */
export const preparePayloadForAddToBag = (orderViewModel, addItemAsyncApi, item, siteName) => {
  const deliveryPayload = {};

  if (isEqual(orderViewModel.orderType?.toLowerCase(), DELIVERY)) {
    const { DeliveryAddress } = orderViewModel;
    deliveryPayload.StreetAddressLine1 = DeliveryAddress.StreetAddressLine1;
    deliveryPayload.StreetAddressLine2 = '';
    deliveryPayload.City = DeliveryAddress.City;
    deliveryPayload.State = DeliveryAddress.State;
    deliveryPayload.Zip = DeliveryAddress.Zip;
    deliveryPayload.Country = DeliveryAddress.Country;
  }

  const comboItems = {};
  const combos = item.combo;

  if (combos && (combos.comboName || combos.ComboName)) {
    comboItems.combo = combos;
    const comboItemsList = combos.items;
    comboItems.combo.ComboItems = comboItemsList;
  }

  return {
    ...deliveryPayload,
    apiEndpoint: addItemAsyncApi,
    CartId: item.orderId,
    StoreId: orderViewModel.store.id,
    OrderType: orderViewModel.orderType || PICKUP,
    MergeCart: true,
    SiteName: siteName,
    IsModalView: true,
    IsAccordionOpen: false,
    IsRecentOrder: false,
    OrderId: item.orderId,
    IsAddToBag: false,
    ProductId: item.productId,
    IsAddToBagFromRecentOrder: true,
    AddItemModel: {
      IsReactResponse: true,
      items: {
        AddCartItems: [
          {
            ...comboItems,
            analytics: {
              CategoryId: item.categoryId,
              CategoryName: item.categoryName,
              ProductName: item.name || '',
              IsCookie: item.isCookie,
            },
            options: !_isEmpty(item.options) ? item.options : undefined,
            productId: item.productId,
            productName: item.name,
            quantity: item.quantity,
            imageUrl: '/ns/images/Menu/USA/ENG/Category_767x767_BlackForestHam_Sandwiches_@1x.jpg',
          },
        ],
      },
      CartId: item.orderId,
      StoreId: orderViewModel.store.id,
      LocationId: item.itemDetails?.locationId ?? orderViewModel.store.id,
      IsKidsMeal: item.isKidsMeal,
      pricingScheme: '1',
      fulfillmentType: orderViewModel.orderType || PICKUP,
      dineIn: false,
      curbside: {
        curbsideInstructions: ' ',
      },
      orderInstructions: ' ',
      siteName: siteName,
    },
  };
};

/**
 * Prepares payload for unavailable modal
 * @param isRecentOrderPage - true if the modal is shown on recent order page
 * @param cartItemId - cart item id
 * @param orderViewModel - orderViewModel object
 * @param siteName - site name
 * @param itemsData - itemsData object
 * @returns {Object} payload
 */
export const preparePayloadForUnavailableModal = (
  isRecentOrderPage,
  cartItemId,
  orderViewModel,
  siteName,
  itemsData,
) => {
  const mergeCart = Number(orderViewModel.currentOrder.cartQuantity) > 0 ? true : false;
  const mergeCartID = mergeCart ? orderViewModel.currentOrder.cartId : '';

  const commonPayload = {
    StoreId: orderViewModel.store.id || getStoreIDByURL(),
    SiteName: siteName,
    IsReactResponse: true,
  };

  const recentOrderPayload = {
    ...commonPayload,
    OrderId: cartItemId,
    CartId: cartItemId,
    StreetAddressLine1: orderViewModel.DeliveryAddress.StreetAddressLine1,
    StreetAddressLine2: '',
    City: orderViewModel.DeliveryAddress.City,
    State: orderViewModel.DeliveryAddress.State,
    Zip: orderViewModel.DeliveryAddress.Zip,
    Country: orderViewModel.DeliveryAddress.Country,
    DirectDelivery: orderViewModel.DeliveryAddress.DirectDelivery,
    QuoteId: orderViewModel.DeliveryAddress.QuoteId,
    OrderType: orderViewModel.orderType,
    IsAddToBag: true,
    ProductId: _get(itemsData, 'productId', ''),
    CurrentCartId: '',
    MergeCart: mergeCart,
    MergeCartId: mergeCartID,
    OrderSourceType: RECENT_ORDER,
  };

  const favOrderPayload = {
    ...commonPayload,
    CurrentOrderCartId: orderViewModel.currentOrder.cartId,
    ReorderedItemCartItemId: cartItemId,
  };

  return isRecentOrderPage ? recentOrderPayload : favOrderPayload;
};

export const getISODate = () => {
  return new Date(Date()).toISOString().substring(0, 19);
};

export const startNewOrder = () => {
  if (orderViewModel.store.id) {
    window.location.href = subwayScript.navigation.getMenuUrl(orderViewModel.store.id);
  } else {
    if (orderViewModel.orderType == 'delivery') {
      window.location.href = subwayScript.navigation.getDeliveryLocatorUrl();
    } else {
      window.location.href = subwayScript.navigation.getLocatorUrl();
    }
  }
};

export const filterItems = (items, isLoggedIn, quantity) => {
  const itemData = items.filter(
    item => item.cssClass === (isLoggedIn ? 'account_loggedIn' : 'account_logged'),
  );

  if (quantity === 0) {
    itemData.push(items.find(item => item.cssClass === 'cart_bag empty'));
  } else {
    itemData.push(items.find(item => item.cssClass === 'cart_bag full'));
  }

  return itemData;
};

export const calculateHeaderHeight = headerHeightCal => {
  let child;
  const secondChild = document.querySelector('#root-react-pr > :nth-child(3)');
  if (secondChild && secondChild.classList.value.includes('defaultHeader__comp')) {
    child = document.querySelector('#root-react-pr > :nth-child(4)');
  } else {
    child = document.querySelector('#root-react-pr > :nth-child(3)');
  }
  if (child) {
    if (child.className !== 'profile__container') {
      child.style.marginTop = headerHeightCal;
    }
    child.id = 'switchbodymaincontent';
  }
};

export const prepateAddress = placeDetails => {
  const addressForm = {
    street_number: '',
    route: '',
    locality: '',
    administrative_area_level_1: '',
    country: '',
    postal_code: '',
    sublocality: '',
    neighborhood: '',
    streetAddressLine2: 'optionalAddress',
  };

  for (let addressData of placeDetails.address_components) {
    let addressTypes = addressData.types;
    for (let element of addressTypes) {
      switch (element) {
        case 'street_number':
        case 'route':
        case 'locality':
        case 'sublocality':
        case 'neighborhood': {
          addressForm[element] = addressData.long_name;
          break;
        }
        case 'administrative_area_level_1':
        case 'country':
        case 'postal_code': {
          addressForm[element] = addressData.short_name;
          break;
        }
        default:
          break;
      }
    }
  }

  if (addressForm.locality === '') {
    if (addressForm.sublocality !== '') {
      addressForm['locality'] = addressForm.sublocality;
    } else {
      addressForm['locality'] = addressForm.neighborhood;
    }
  }
  return addressForm;
};
export const navigateToReloadCard = (url, userAgent, paymentId, iSoDate, data) => {
  let target = '_blank';

  if (userAgent) {
    target = '_top';
  }

  $.redirect(
    url,
    {
      card_number: paymentId,
      pin: '',
      purchase_origin_type: '',
      cardholder_name: '',
      currency_code: '',
      activation_timestamp: iSoDate,
      hmac_signature: data.encryptedKey,
    },
    '',
    target,
  );
};
export const bannerHeight = () => {
  const bannerClass = document.querySelector('.banner-notification');
  return _get(bannerClass, ['offsetHeight'], 0);
};

export const getButtonOption = (list, ingredientName, optionId, buttonName) => {
  let buttonArr = [];
  const ingredientArr = list.filter(item => item.id == ingredientName);
  !_isEmpty(ingredientArr) &&
    !_isEmpty(ingredientArr[0].options) &&
    ingredientArr[0].options.map(option => {
      if (option.id == optionId) {
        buttonArr =
          !_isEmpty(option.additionalOptions) &&
          !_isEmpty(option.additionalOptions.buttonNames) &&
          option.additionalOptions.buttonNames.filter(button => button.originalName == buttonName);
      }
    });
  return buttonArr.length > 0 ? buttonArr[0] : {};
};
export const footerMIAM = () => {
  hideElement('global-footer__container');
  hideElement('global-footer__divider');
  hideElement('horisocial-footer__container');
  hideElement('legal-copy__footer');
};

function hideElement(className) {
  const elements = document.getElementsByClassName(className);
  if (elements?.length > 0) {
    elements[0].style.display = 'none';
  }
}

export const constructProductArray = cartCurrentItem => {
  const productArr = [
    {
      productName: cartCurrentItem.analytics.productName,
      productSize: cartCurrentItem.analytics.size,
      image: cartCurrentItem.imageUrl,
    },
  ];
  if (!_isEmpty(cartCurrentItem.combo)) {
    cartCurrentItem.combo.items.forEach(item => {
      productArr.push({ productName: item.productName, productSize: '', image: item.imageUrl });
    });
  }
  return productArr;
};

export const getDefaultSauces = (modifierOptions, noSauceText = null, noSaucePlease = false, withText = '') => {
  const defaultItem = modifierOptions?.find(item => item.isDefault);
  const result = defaultItem ? `${withText} ${defaultItem.name}` : noSaucePlease ? noSauceText : '';
  return result;
};

export const selecteComboListData = (
  ID,
  selectedComboList,
  selectedCookies,
  currentCookieQuantity,
  groupName,
  remainingChoices,
  groupID,
  comboOptions,
  multiSelectOption,
  quantity
) => {
  const [selectedComboGroup] = comboOptions.options.filter(
    groupOption => groupOption.id === groupID,
  );
  const selectedComboOption = selectedComboGroup?.attributes?.filter(options => {
    if (selectedComboGroup.quantity > 1) {
      if (selectedCookies.includes(options.choiceId)) {
        if (currentCookieQuantity > 0 && currentCookieQuantity < quantity && !multiSelectOption) {
          if (currentCookieQuantity === 1) {
            options.buttonData[0].selected = true;
            options.buttonData[0].isDisabled = currentCookieQuantity === 1 ? true : false;
            options.buttonData[1].selected = false;
            options.buttonData[1].isDisabled = currentCookieQuantity === quantity ? true : false;
          }
        }
        if (currentCookieQuantity === quantity && !multiSelectOption) {
          options.buttonData[0].selected = true;
          options.buttonData[0].isDisabled = true;
          options.buttonData[1].selected = false;
          options.buttonData[1].isDisabled = false;
        }
        options.isSelected = true;
      }
      return options.isSelected;
    }
    return (options.choiceId === ID || options.value === ID) && options.isSelected;
  });

  let newselectedComboList;
  if (selectedComboList) {
    let index = selectedComboList.findIndex(x => x['groupName'] == groupName);
    if (index == -1) {
      newselectedComboList = [
        ...selectedComboList,
        {
          groupName: groupName,
          selectedItem: [...selectedComboOption],
          allComboData: comboOptions,
          groupID: groupID,
          remainingChoices: remainingChoices,
        },
      ];
    } else {
      selectedComboList[index] = {
        groupName: groupName,
        selectedItem: [...selectedComboOption],
        allComboData: comboOptions,
        groupID: groupID,
        remainingChoices: remainingChoices,
      };
      newselectedComboList = _cloneDeep(selectedComboList);
    }
  }
  return { selectedComboOption, newselectedComboList };
};

export const getUpdatedCalCountModifier = (modiefierGroupList) => {
  return modiefierGroupList?.map(item => {
    if (item.modifierOptions && item.modifierOptions.length > 0) {
      const defaultModifier = item.modifierOptions.find(modifier => modifier.isDefault);
      if (defaultModifier) {
        item.calories = String(Number(defaultModifier.calories || 0) + Number(item.calories || 0));
      }
    }
    return item;
  });
}